import { useState, useEffect } from "react";

import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";

// Fetch
import axios from "axios";

// Components

function Rules() {
  const [data, setData] = useState(null);
  const [select, setSelect] = useState("main");

  useEffect(() => {
    axios.get("/content/terms-of-use").then((r) => {
      setData(r.data);
    });
  }, []);

  if (!data) return null;

  return (
    <Box sx={{ my: 4 }}>
      <Container sx={{ mt: 5 }} maxWidth="md">
        <Typography
          sx={{
            mx: 2,
          }}
          textAlign='center'
          variant="h6"
        >
          Правила сайта и AML/KYC
        </Typography>

        <Card
          sx={{
            border: "2px solid #3A3255",
            borderRadius: 4,
            bgcolor: "#211A35",
            height: "100%",
            mt: 4,
          }}
        >
          <CardContent>
            <Typography
              dangerouslySetInnerHTML={{ __html: data.main }}
            ></Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export default Rules;
