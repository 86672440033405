import { useState } from "react";
import { Tooltip, Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { Icon } from "@iconify/react";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#5EC4D1",
  },
}));

function Copy({ text }) {
  let [copied, setCopied] = useState(false);
  return (
    <Box sx={{ p: "2px" }} onMouseOver={() => setCopied(false)}>
      <CopyToClipboard onCopy={() => setCopied(true)} text={text}>
        <StyledTooltip title={copied ? "Скопировано!" : "Скопировать"}>
          <IconButton sx={{ color: "#5EC4D1", ml: 1, p: 0 }}>
            <Icon fontSize="24px" icon="ph:copy-bold" />
          </IconButton>
        </StyledTooltip>
      </CopyToClipboard>
    </Box>
  );
}

export default Copy;
