import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    allVariants: {
      color: "#fff",
    },
  },
  palette: {
    primary: {
      main: "#463E5E",
      first: "#5EC3D1",
      second: "#886EEF",
      third: "#C9478D",
      link: "#886EEF",
      lightLink: "#a391e9",
      error:'#EE3275'
    },
    error: {
      main: '#EE3275'
    },

    background: {
      default: "#1D1730",
      paper: "#2E2649",
    },
    gradients: {
      default: `linear-gradient(#2E2649, #2E264900)`,
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          background: "#3A3255",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.MuiTab-root": {
            color: "#9D94B5",
            textTransform: 'capitalize',
            "&:hover": {
              borderBottom: "1px solid #5EC4D1",
              color: "#5EC4D1",
            },
          },
          "&.Mui-selected": {
            color: "#5EC4D1",
            borderBottom: "1px solid #5EC4D1",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: '#5EC4D1',
            height: '1px'
          }
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.MuiListItemButton-root": {
            color: "#9D94B5",
            // borderBottom: "1px solid #382D55",
            "&:hover": {
              backgroundColor: "rgba(56, 45, 85, 0.51)",
              // borderBottom: "1px solid #5EC4D1",
              // color: '#5EC4D1',
            },
          },
          // "&.Mui-selected": {
          //   color: '#5EC4D1',
          //   borderBottom: "1px solid #5EC4D1",
          //   borderColor: "#5EC4D1",
          // },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.MuiCheckbox-root.Mui-checked": {
            color: "#5EC4D1",
          },
          //
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          background: "#382D55",
          borderRadius: "20px",

          "&::placeholder": {
            textOverflow: "ellipsis !important",
            color: "#9D94B5",
          },

          "& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button":
            {
              "-webkit-appearance": "none",
              margin: 0,
            },
          "& input": {
            color: "white",
            paddingLeft: "22px",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "20px",
            "& fieldset": {
              borderRadius: "20px",
            },
            "&:hover fieldset": {
              borderRadius: "20px",

              borderColor: "#7D70D6",
            },
            "&.Mui-focused fieldset": {
              borderRadius: "20px",

              borderColor: "#7D70D6",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "25px",
          // padding: '10px 20px'
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            textTransform: "none",
            color: "#1D1730",
            fontSize: "14px",
            fontWeight: 500,
            padding: "8px 28px",
            background:
              "linear-gradient(90deg, rgba(123,97,210,1) 0%, rgba(133,186,234,1) 100%)",
            "&:hover": {
              background: "linear-gradient(90deg, #7B61D2 0%, #7B61D2 100%)",
              color: "#FFFFFF",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            border: "1px solid #463E5E",
            textTransform: "none",
            color: "#DAD4EA",
            fontSize: "14px",
            fontWeight: 500,
            padding: "8px 28px",
            // background: 'linear-gradient(90deg, rgba(123,97,210,1) 0%, rgba(133,186,234,1) 100%)',
            // '&:hover': {
            //   background: 'linear-gradient(90deg, #7B61D2 0%, #7B61D2 100%)',
            //   color: '#FFFFFF'
            // },
          },
        },
        // {
        //   props: { variant: 'dashed', color: 'secondary' },
        //   style: {
        //     border: `4px dashed ${red[500]}`,
        //   },
        // },
      ],
    },
  },
});

export default theme;
