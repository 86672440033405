// Mui
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";

function SumsCard({ balance, income, withdraw }) {
  return (
    <Box
      sx={{
        border: "2px solid #85BAEA",
        borderRadius: 4,
        bgcolor: "#211A35",
      }}
    >
      <CardContent>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MinCard title="Баланс" sum={balance} style={{mb: 2}} />
          </Grid>
          <Grid item xs={6}>
            <MinCard title="Заработано" sum={income} />
          </Grid>
          <Grid item xs={6}>
            <MinCard title="Выведено" sum={withdraw} />
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
}

function MinCard({ title, sum, style = {} }) {
  return (
    <Box sx={{ ...style }}>
      <Typography sx={{ fontSize: "16px", color: "#E9E1FF" }}>
        {title}
      </Typography>
      <Typography sx={{ mt: 0, fontSize: "20px" }}>{sum} RUB</Typography>
    </Box>
  );
}

export default SumsCard;
