import { useState } from "react";

// Mui
import {
  Box,
  Container,
  Typography,
  Button,
  Drawer,
  CardContent,
  makeStyles,
  IconButton,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Color from "color";
import HeaderMenu from "components/HeaderMenu";

// Router
import { Link, NavLink, useNavigate } from "react-router-dom";

// Icon
import { Icon } from "@iconify/react";

// Compoents
import Logo from "components/Logo/Logo";

import config from "configs/api";

// Store
import { observer } from "mobx-react-lite";
import AccStore from "store/account";
import ClientMenu from "./ClientMenu";

const links = [
  {
    name: "Правила сайта и AML/KYC",
    link: "/rules",
  },
  {
    name: "Партнерам",
    link: "/сooperation",
  },
  {
    name: "Контакты",
    link: "/our-contacts",
  },
  // {
  //   name: "FAQ",
  //   link: "/faq",
  // },
];

function Header({ style = {} }) {
  let [showMenu, setShowMenu] = useState(false);
  let [showAccMenu, setShowAccMenu] = useState(false);

  // Hooks
  const theme = useTheme();
  const desktopScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  let isAuth = AccStore.isAuth;
  // Handlers

  const accountClickHandler = () => {
    if (desktopScreen) {
      navigate("/account/profile");
    } else {
      setShowAccMenu(true);
    }
  };

  let accountBtn = (
    <Box>
      <IconButton
        onClick={accountClickHandler}
        sx={{
          mx: 1,
          height: "40px",
          width: "40px",
          borderRadius: "14px",
          transition: "all 0.2s",
          color: "#FFFFFF",
          backgroundColor: "rgba(123,97,210,1)",
          "&:hover": {
            backgroundColor: Color("rgba(123,97,210,1)").lighten(0.2).hex(),
            // background: "linear-gradient(90deg, #7B61D2 0%, #7B61D2 100%)",
          },
        }}
      >
        <Icon icon="iconamoon:profile-bold" />
      </IconButton>
    </Box>
  );

  let supportBtn = (
    <Box
      sx={{
        mr: 1,
        display: "flex",
        alignItems: "center",
        "& svg": {
          color: "#fff",
        },
      }}
    >
      <IconButton
        // sx={{ ml: 1 }}
        sx={{p: 1}}
        onClick={() => window.jivo_api.open()}
      >
        <Icon fontSize='20px' icon="ph:chat-bold" />
      </IconButton>
      <IconButton
        sx={{ ml: 0.5, p: 1 }}
        onClick={() => (window.location.href = `mailto:${config.EMAIL}`)}
      >
        <Icon fontSize='20px' icon="ic:outline-email" />
      </IconButton>
      <IconButton
        sx={{ ml: 0.5, p: 1 }}
        onClick={() => window.open(config.TELEGRAM_LINK, "_blank")}
      >
        <Icon fontSize='20px' icon="iconoir:telegram-circle" />
      </IconButton>
    </Box>
  );

  // <Button
  //   sx={{
  //     color: "white",
  //     fontSize: "12px",
  //     lineHeight: "19px",
  //     fontWeight: 500,
  //     padding: "6px 12px",
  //   }}
  //   endIcon={<Icon icon="bx:comment" />}
  // >
  //   {`${desktopScreen ? "Поддержка " : ""}24/7`}
  // </Button>

  // Desktop components
  let desktopComponent = (
    <>
      <Box sx={{ display: "flex", alignItems: "center", ml: 3, flexGrow: 1 }}>
        {links.map((l) => {
          return (
            <Typography
              sx={{
                fontSize: "15px",
                textDecoration: "none",
                fontWeight: 200,
                mr: 2,
                color: "#DAD4EA",
                "&.active": {
                  color: "#fff",
                },
              }}
              to={l.link}
              component={NavLink}
              variant="body1"
            >
              {l.name}
            </Typography>
          );
        })}
      </Box>
      <Box sx={{ pr: 1, display: "flex", alignItems: "center" }}>
        {supportBtn}

        {isAuth ? (
          accountBtn
        ) : (
          <>
            <Button
              component={Link}
              to="/register"
              variant="outlined"
              sx={{ mx: 1 }}
            >
              Регистрация
            </Button>
            <Button component={Link} to="/login" variant="contained">
              Войти
            </Button>
          </>
        )}
      </Box>
    </>
  );

  const mobileComponent = (
    <Box
      sx={{
        flexGrow: 1,
        pr: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {supportBtn}
      {isAuth && accountBtn}
      <Box
        sx={{
          cursor: "pointer",
          transition: "all 0.5s",
          "&:hover": { color: "white" },
          fontSize: "30px",
          color: "#DAD4EA",
          display: "contents",
          ml: 4,
        }}
        onClick={() => setShowMenu(!showMenu)}
      >
        <Icon icon={"line-md:menu"} />
      </Box>
    </Box>
  );

  return (
    <Box>
      <Container sx={{ ...style }} maxWidth="lg">
        <Box
          sx={{
            bgcolor: (t) => t.palette.background.paper,
            p: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: 100,
            position: "relative",
          }}
        >
          <Logo />
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            {desktopScreen ? desktopComponent : mobileComponent}
          </Box>
        </Box>
      </Container>
      <ClientMenu
        show={showAccMenu}
        handleClose={() => setShowAccMenu(false)}
      />
      <Drawer
        anchor={"top"}
        open={showMenu}
        sx={{
          "& .MuiDrawer-paper": {
            minHeight: "100vh",
            maxHeight: "100vh",
            bgcolor: (t) => t.palette.background.default,
            pt: 3,
            px: 3,
            pb: 3,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      >
        <Box sx={{}}>
          <HeaderMenu
            closeHandler={() => setShowMenu(false)}
            style={{ mb: 5 }}
          />
          <Box>
            {links.map((l) => {
              return (
                <Typography
                  onClick={() => setShowMenu(false)}
                  sx={{
                    fontSize: "20px",
                    textDecoration: "none",
                    fontWeight: 200,
                    display: "block",
                    mb: 2,
                    color: "#DAD4EA",
                    "&.active": {
                      color: "#fff",
                    },
                  }}
                  to={l.link}
                  component={NavLink}
                  variant="body1"
                >
                  {l.name}
                </Typography>
              );
            })}
          </Box>
        </Box>
        {!isAuth && (
          <Box>
            <Button
              onClick={() => {
                navigate("/register");
                setShowMenu(false);
              }}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            >
              Регистрация
            </Button>
            <Button
              onClick={() => {
                navigate("/login");
                setShowMenu(false);
              }}
              fullWidth
              variant="contained"
            >
              Войти
            </Button>
          </Box>
        )}
      </Drawer>
    </Box>
  );
}

export default observer(Header);
