import { Box, Typography, FormHelperText, TextField } from "@mui/material";

import { Controller } from "react-hook-form";

import InputMask from "react-input-mask";

function FieldList({ list, errors, control, register }) {
  return (
    <Box>
      {list.map((f) => {
        let validate = f.validate;
        let isMask = false;
        let maskChar = "";
        let mask = "";

        let fieldValidate = undefined;

        if (validate == "card" || validate == "phone") {
          isMask = true;

          if (validate == "card") {
            mask = "9999 9999 9999 9999";
            maskChar = " ";
            fieldValidate = (val) => {
              let number = val.replace(/\D/g, "");
              if (number.length < 16) {
                return "Введите верный формат!";
              }
            };
          }

          if (validate == "phone") {
            mask = "+7(999) 999-99-99";
            maskChar = "";
            fieldValidate = (phone) => {
              let number = phone.replace(/\D/g, "");

              if (number.length < 11) {
                return "Введите верный формат!";
              }
            };
          }
        }

        return (
          <Box key={f.id} sx={{ mt: 2 }}>
            <Typography color="#D9D3E8" sx={{ mb: 1.2 }} variant="h6">
              {f.label}
            </Typography>
            <Controller
              name={f.id}
              control={control}
              render={({ field: { onChange, value } }) => {
                if (isMask) {
                  return (
                    <InputMask
                      value={value}
                      onChange={onChange}
                      placeholder={f.placeholder || ""}
                      fullWidth
                      {...register(f.id, {
                        required: "Пожалуйста, заполните это поле!",
                        maxLength: 100,
                        validate: fieldValidate,
                      })}
                      error={Boolean(errors[f.id]?.message)}
                      mask={mask}
                      maskChar={maskChar}
                    >
                      {(e) => <TextField {...e} />}
                    </InputMask>
                  );
                } else {
                  return (
                    <TextField
                      value={value}
                      onChange={onChange}
                      placeholder={f.placeholder || ""}
                      fullWidth
                      {...register(f.id, {
                        required: "Пожалуйста, заполните это поле!",
                        maxLength: 100,
                        fieldValidate,
                      })}
                      error={Boolean(errors[f.id]?.message)}
                    />
                  );
                }
              }}
            />
            {errors[f.id]?.message && (
              <FormHelperText
                sx={{ fontSize: "14px", fontWeight: "500" }}
                error
              >
                {errors[f.id]?.message}
              </FormHelperText>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

// <TextFieldWrap
//

//
//   validate={f.validate}
//   key={f.id}

// />

export default FieldList;
