import { useEffect, useState } from "react";
// Mui
import { ThemeProvider } from "@mui/material/styles";

import { useSearchParams } from "react-router-dom";

//UI
import { Typography, Box, LinearProgress } from "@mui/material";

// Styles
import "./App.css";
import theme from "./styles/theme/index";

// Modules
import Footer from "./modules/Footer/Footer";
import Header from "./modules/Header/Header";

// Router
import Router from "./Router";

// Store
import AccStore from "store/account";

// Notify
import { ToastContainer } from "react-toastify";
import customToast from "customToast";

// import "./socket";

//
import axios from "axios";

function App() {
  const [searchParams] = useSearchParams();

  let rid = searchParams.get("rid");

  useEffect(() => {
    // connect();
    let token = localStorage.getItem("access_token");

    if (token) {
      axios
        .get("/account")
        .then((res) => {
          AccStore.setAccount(res.data);
        })
        .catch((e) => {});
    }

    if (rid) {
      AccStore.setRid(rid);
    }
  }, []);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            backgroundColor: (t) => t.palette.background.default,
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Header style={{ mt: 2 }} />
          <Box sx={{ flexGrow: 1, minHeight: "calc(100vh - 70px)" }}>
            <Router />
          </Box>
          <Footer />
        </Box>
        <ToastContainer
          position="bottom-center"
          className="toast-container"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          pauseOnFocusLoss
          pauseOnHover
          theme="dark"
        />
      </ThemeProvider>
    </div>
  );
}

export default App;
