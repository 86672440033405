// Mui
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import Color from "color";

import { Empty } from "components/Emty";
import moment from "moment";

function ReferralsHistory({ list = [] }) {
  let hasHistory = !!list.length;

  let listRender = list.map((item) => {
    return <HCard key={item._id} data={item} />;
  });

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">История реферальных начислений</Typography>
      </Box>
      <Box sx={{ mt: 2, maxHeight: "40vh" }}>
        {hasHistory ? listRender : <Empty text="Нет начислений" />}
      </Box>
    </Box>
  );
}

function HCard({ data }) {
  return (
    <Card sx={{mb: 1.5}}>
      <CardContent
        sx={{
          p: "12px 16px !important",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Typography sx={{ fontSize: "18px" }}>{data.sum} RUB</Typography>
        <Typography
          sx={{ fontSize: "12px", color: Color("#fff").darken(0.2).hex() }}
        >
          {moment(data.createdAt).format("HH:mm DD.MM.YYYY")}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ReferralsHistory;
