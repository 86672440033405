// Mui
import {
  Box,
  Grid,
  Container,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// routing
import { NavLink, Outlet, useNavigate } from "react-router-dom";

// Store
import AccStore from "store/account";
import { observer } from "mobx-react-lite";

// Icon
import { Icon } from "@iconify/react";

const links = [
  {
    name: "Профиль",
    link: "/account/profile",
    id: 1,
    icon: "mingcute:profile-line",
  },
  {
    name: "Заказы",
    link: "/account/orders",
    id: 2,
    icon: "gg:arrows-exchange-alt",
    forVerified: true,
  },
  {
    name: "Реферальная программа",
    link: "/account/referrals",
    id: 3,
    icon: "ph:users-bold",
    forVerified: true,
  },
];

function AccountMenu() {
  const navigate = useNavigate();

  const theme = useTheme();
  const phoneScreen = useMediaQuery(theme.breakpoints.up("md"));

  const email_verified = AccStore?.account?.email_verified;

  const desktopMenu = (
    <>
      <Grid item md={2.6}>
        {links.map((l) => {
          let isDisabled = !email_verified && l.forVerified;

          return (
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                mb: 2.5,
                transition: "0.2s all",
                color: (t) => (isDisabled ? "gray" : undefined),
                "&.active": {
                  color: (t) => t.palette.primary.link,
                },
                fontSize: "16px",
                "&:hover": {
                  color: (t) => t.palette.primary.lightLink,
                },
                pointerEvents: isDisabled ? "none" : undefined,
                cursor: isDisabled ? "not-allowed" : "pointer",
              }}
              component={NavLink}
              to={l.link}
            >
              <Icon fontSize="24px" icon={l.icon} />
              <span style={{ marginLeft: "8px" }}>{l.name}</span>
            </Typography>
          );
        })}
        <Divider sx={{ height: "1.5px" }} />

        <Typography
          onClick={() => {
            AccStore.logoutAccount();
            navigate('/');
          }}
          sx={{
            mt: 3,
            display: "flex",
            alignItems: "center",
            mb: 2.5,
            transition: "0.2s all",
            "&:hover": {
              color: (t) => t.palette.primary.link,
            },
            "&.active": {
              color: (t) => t.palette.primary.link,
            },
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          <Icon fontSize="24px" icon="mdi:logout" />
          <span style={{ marginLeft: "8px" }}>Выход</span>
        </Typography>
      </Grid>
    </>
  );

  return (
    <Box sx={{ my: 6 }}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {phoneScreen ? desktopMenu : null}
          <Grid item xs={12} md={8.8}>
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default observer(AccountMenu);
