import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";

import config from "configs/api";

import { Icon } from "@iconify/react";

export default function Contacts() {
  return (
    <Box sx={{ my: 4 }}>
      <Container maxWidth="lg">
        <Typography sx={{ mb: 4 }} textAlign="center" variant="h6">
          Контакты
        </Typography>
      </Container>
      <Container  maxWidth="md">
        <Card
          sx={{
            border: "2px solid #3A3255",
            borderRadius: 4,
            bgcolor: "#211A35",
            height: "100%",
            mt: 4,
          }}
        >
          <CardContent>
            <Grid
              sx={{
                "& svg": {
                  color: "#fff",
                },
              }}
              container
              justifyContent="space-around"
              spacing={2}
            >
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
                item
                md={4}
                xs={12}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: "max-content",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    (window.location.href = `mailto:${config.EMAIL}`)
                  }
                >
                  <Icon fontSize="42px" icon="ic:outline-email" />
                  <Typography sx={{mt: 1}} variant="body1">{config.EMAIL}</Typography>
                </Box>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
                item
                md={4}
                xs={12}
              >
                <Box
                  sx={{
                    cursor: "pointer",

                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: "max-content",
                    alignItems: "center",
                  }}
                  onClick={() => window.open(config.TELEGRAM_LINK, "_blank")}
                >
                  <Icon fontSize="42px" icon="iconoir:telegram-circle" />
                  <Typography sx={{mt: 1}} variant="body1">Чат поддержки</Typography>
                </Box>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
                item
                md={4}
                xs={12}
              >
                <Box
                  sx={{
                    cursor: "pointer",

                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: "max-content",
                    alignItems: "center",
                  }}
                  onClick={() => window.jivo_api.open()}
                >
                  <Icon fontSize="42px" icon="ph:chat-bold" />
                  <Typography sx={{mt: 1}} variant="body1">Чат поддержки</Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
