import { useEffect } from "react";
import {
  Dialog,
  CardContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";

// Icon
import { Icon } from "@iconify/react";

import axios from "axios";

function ResetMailModal({ open, handleClose, email }) {
  useEffect(() => {
    if (open) {
      axios
        .get("/account/recovery-password", {
          params: {
            email,
          },
        })
        .then(() => {})
        .catch(() => {});
    }
  }, [open]);

  return (
    <Dialog
      PaperProps={{
        sx: {
          border: "2px solid #3A3255",
          borderRadius: 4,
          bgcolor: "#211A35",
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      ></CardContent>
      <ResetMailContent email={email} />
    </Dialog>
  );
}

function ResetMailContent({ email }) {
  return (
    <CardContent
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Icon color="#886EEF" fontSize="70px" icon="ri:mail-send-line" />
      <Typography textAlign="center" sx={{ mt: 2, mb: 1 }} variant="body1">
        Мы отправили вам письмо на <b>{email}</b>.
      </Typography>
      <Typography textAlign="center" variant="body2">
        Для сброса пароля перейдите по ссылке из письма и установите новый
        пароль.
      </Typography>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 4
        }}
      >
        <Typography variant="body2">Не получили письмо?</Typography>
        <Typography
          variant="body2"
          sx={{ ml: 1, textDecoration: "none", cursor: "pointer" }}
          // to="/register"
          // color="primary.link"
        >
          Отправить снова
        </Typography>
      </Box> */}
    </CardContent>
  );
}

export { ResetMailModal, ResetMailContent };
