import { useState, useEffect, useMemo } from "react";

// Mui
import {
  Box,
  Container,
  Typography,
  Card,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  CardContent,
} from "@mui/material";

// fetch
import axios from "axios";

// Hooks
import { useForm, Controller } from "react-hook-form";
// Routing
import { Link, useLocation, useNavigate } from "react-router-dom";

// Store
import AccStore from "store/account";
import { toast } from "react-toastify";
import customToast from "customToast";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function Login() {
  let [isSubmitting, setSubmiting] = useState(false);

  let query = useQuery();
  let navigate = useNavigate();

  let hash = query.get("activate");

  useEffect(() => {
    if (!hash) return;
    axios
      .get("/account/verify-mail", { params: { hash } })
      .then((res) => {
        customToast.success("Аккаунт активирован");
      })
      .catch((e) => {});
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onSubmit", defaultValues: { email: "", password: "" } });

  const onSubmit = handleSubmit((data) => {
    setSubmiting(true);
    axios
      .post("/account/sign-in", data)
      .then((res) => {
        AccStore.setAccount(res.data.account);
        localStorage.setItem("access_token", res.data.access_token);
        navigate("/account/profile");
      })
      .catch((e) => {})
      .finally(() => {
        setSubmiting(false);
      });
  });

  return (
    <Box sx={{ mt: 6 }}>
      <Container maxWidth="xs">
        <Typography textAlign="center" variant="h4">
          Вход в учетную запись
        </Typography>
        <Box sx={{ my: 4 }}>
          <Card
            sx={{
              border: "2px solid #3A3255",
              borderRadius: 4,
              bgcolor: "#211A35",
              height: "100%",
              mt: 4,
            }}
          >
            <CardContent
              sx={{
                p: "32px 24px !important",
              }}
              noValidate
              onSubmit={onSubmit}
            >
              <Controller
                name={"email"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      error={Boolean(errors.email?.message)}
                      {...register("email", {
                        required: "Введите email!",
                        maxLength: 50,
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Не верный Email!",
                        },
                      })}
                      fullWidth
                      autoFocus
                      // helperText={errors.email?.message}
                      placeholder="E-mail"
                      name="email"
                      type="email"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                    />
                    {errors.email?.message && (
                      <FormHelperText error color="error">
                        {errors.email?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                name={"password"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      {...register("password", {
                        required: "Обязательное поле!",
                        minLength: {
                          value: 6,
                          message: "Мин. 6 символов!",
                        },
                        maxLength: 100,
                      })}
                      error={Boolean(errors.password?.message)}
                      fullWidth
                      // sx={{ mb: 1 }}
                      // helperText={errors.password?.message}
                      placeholder="Пароль"
                      name="password"
                      type="password"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                    />
                    {errors.password?.message && (
                      <FormHelperText error color="error">
                        {errors.password?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography
                  sx={{ textDecoration: "none" }}
                  color="primary.link"
                  variant="body2"
                  component={Link}
                  to="/reset-password"
                >
                  Забыли пароль?
                </Typography>
              </Box>

              <Button
                sx={{
                  mt: 3,
                }}
                color="primary"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                type="submit"
                fullWidth
                onClick={onSubmit}
                variant="contained"
              >
                Войти
              </Button>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <Typography>Нет аккаунта?</Typography>
                <Typography
                  variant="body2"
                  sx={{ ml: 1, textDecoration: "none" }}
                  component={Link}
                  to="/register"
                  color="primary.link"
                >
                  Зарегистрироваться
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

export default Login;
