import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  FormControl,
  TextField,
  Button,
  CircularProgress,
  FormHelperText,
} from "@mui/material";

import axios from "axios";
// Hooks
import { useForm, Controller } from "react-hook-form";
import { Icon } from "@iconify/react";

import { Link, useLocation, useNavigate } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
export default function RecoveryPassword() {
  const [isChanged, setIsChanged] = useState(false);

  return (
    <Box sx={{ mt: 6 }}>
      <Container maxWidth="xs">
        <Typography textAlign="center" variant="h4">
          Смена пароля
        </Typography>
        <Box sx={{ my: 4 }}>
          <Card
            sx={{
              border: "2px solid #3A3255",
              borderRadius: 4,
              bgcolor: "#211A35",
              height: "100%",
              mt: 4,
            }}
          >
            <CardContent
              sx={{
                p: "32px 24px !important",
              }}
            >
              {isChanged ? (
                <SuccessContent />
              ) : (
                <RequestContent setIsChanged={setIsChanged} />
              )}
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

function SuccessContent({}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Icon color="#886EEF" fontSize="70px" icon="icon-park-outline:success" />
      <Typography textAlign="center" sx={{ mt: 2, mb: 1 }} variant="body1">
        Пароль успешно изменен
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Typography
          variant="body2"
          sx={{ ml: 1, textDecoration: "none" }}
          component={Link}
          to="/login"
          color="primary.link"
        >
          Вернуться ко входу
        </Typography>
      </Box>
    </Box>
  );
}

function RequestContent({ setIsChanged }) {
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { password: "", confirmPassword: "" },
  });
  let [isSubmitting, setSubmiting] = useState(false);

  let query = useQuery();
  //

  const onSubmit = handleSubmit((data) => {
    if (data.password !== data.confirmPassword) {
      return setError("confirmPassword", { message: "Пароли не совпадают" });
    }
    setSubmiting(true);
    axios
      .get("/account/recovery-password", {
        params: {
          password: data.password,
          code: query.get("code"),
        },
      })
      .then(() => {
        setIsChanged(true);
      })
      .catch((e) => {})
      .finally(() => {
        setSubmiting(false);
      });
  });

  return (
    <Box noValidate component="form" onSubmit={onSubmit}>
      <CardContent sx={{ p: "8px !important" }}>
        <Typography sx={{ mb: 3 }} textAlign="center">
          Введите новый пароль
        </Typography>
      </CardContent>

      <Controller
        name={"password"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              error={Boolean(errors.password?.message)}
              {...register("password", {
                required: "Обязательное поле!",
                minLength: {
                  value: 6,
                  message: "Мин.  6 символов!",
                },
                maxLength: 100,
              })}
              fullWidth
              autoFocus
              // helperText={errors.email?.message}
              placeholder="Новый пароль"
              name="password"
              type="password"
              variant="outlined"
              onChange={onChange}
              value={value}
            />
            {errors.password?.message && (
              <FormHelperText error color="error">
                {errors.password?.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
      <Controller
        name={"confirmPassword"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              error={Boolean(errors.confirmPassword?.message)}
              {...register("confirmPassword", {
                required: "Обязательное поле!",
                minLength: {
                  value: 6,
                  message: "Мин.  6 символов!",
                },
                maxLength: 100,
              })}
              fullWidth
              autoFocus
              // helperText={errors.email?.message}
              placeholder="Повторите пароль"
              name="confirmPassword"
              type="password"
              variant="outlined"
              onChange={onChange}
              value={value}
            />
            {errors.confirmPassword?.message && (
              <FormHelperText error color="error">
                {errors.confirmPassword?.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Button
        sx={{
          mt: 3,
        }}
        color="primary"
        startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
        disabled={isSubmitting}
        type="submit"
        fullWidth
        variant="contained"
      >
        Сохранить
      </Button>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Typography
          variant="body2"
          sx={{ ml: 1, textDecoration: "none" }}
          component={Link}
          to="/login"
          color="primary.link"
        >
          Вернуться ко входу
        </Typography>
      </Box> */}
    </Box>
  );
}
