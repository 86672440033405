import { Box, FormControlLabel, Checkbox, Typography } from "@mui/material";

import { Icon } from "@iconify/react";

import { Link } from "react-router-dom";

function AgreementConfirm({ checked, setChecked, style = {}, error }) {
  return (
    <Box sx={{ ...style }}>
      <Box
        onClick={(e) => setChecked(!checked)}
        sx={{ display: "flex", alignItems: "center", width: "max-content" }}
      >
        <Box
          sx={{
            cursor: "pointer",
            width: "36px",
            height: "36px",
            border: (t) =>
              `1px solid ${!error ? "#463E5F" : t.palette.error.main} `,
            borderRadius: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            background: checked ? "#5EC4D1" : "transparent",
            transition: "all 0.1s",
          }}
        >
          {checked && <Icon fontSize="18px" icon="fe:check" />}
        </Box>
        <Typography
          sx={{
            fontSize: "15px",
            color: "#BBB3D0",
            ml: 2,
            fontWeight: 300,
            lineHeight: "18px",
          }}
        >
          Согласен с <br />
          <Typography
            variant="body2"
            sx={{
              display: "inline-block",
              textDecoration: "none",
              wordBreak: "break-word",
            }}
            component={Link}
            to="/rules"
            target="_blank"
            color="primary.link"
          >
            правилами обмена и политикой AML/KYC
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
}

export default AgreementConfirm;
