import axios from "axios";
import CONFIG from "./api";

// Notify
import { toast } from "react-toastify";
import profileStore from "store/account";
import customToast from "customToast";

axios.defaults.baseURL = CONFIG.API;

axios.interceptors.request.use(function (config) {
  let token = localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let status = error?.response?.status;
    let message = error?.response?.data?.message;

    if (message && status !== 403) {
      customToast.error(message);
    }

    if (status === 403) {
      profileStore.logoutAccount();
    }
    return Promise.reject(error);
  }
);
