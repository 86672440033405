// import { useState } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

// import { CopyToClipboard } from "react-copy-to-clipboard";
// import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import { Icon } from "@iconify/react";

import Copy from "components/Copy";

import ReverifyCard from "modules/VerifyCard/VerifyCard";

function StatusInfoCard({
  status_name,
  error_comment,
  payment_requisites,
  payment_sum,
  buy_code,
  image,
  setImage,
}) {
  let component;

  // console.log(status_name, 'status_name')

  switch (status_name) {
    case "reverify_card":
      component = (
        <ReverifyCard
          image={image}
          setImage={setImage}
          isReverify
          comment={error_comment}
        />
      );
      break;
    case "verify_card":
      component = <VerifyCard />;
      break;
    case "client_payment":
      component = (
        <Pay
          payment_requisites={payment_requisites}
          payment_sum={payment_sum}
          buy_code={buy_code}
        />
      );
      break;
    case "check_client_payment":
      component = <CheckPayment />;
      break;
    case "confirm_client_payment":
      component = <ConfirmPayment />;
      break;
    case "successfully":
      component = <Success />;
      break;
    case "rejected":
      component = <Rejected error_comment={error_comment} />;
      break;
    default:
      component = <Typography>Неизвестный статус</Typography>;
  }

  let borderColor = "#3A3255";

  if (status_name == "rejected") {
    borderColor = "#EE3275";
  } else if (status_name == "successfully") {
    borderColor = "#5EC4D1";
  }

  return (
    <Card
      sx={{
        border: `2px solid ${borderColor}`,
        borderRadius: 4,
        bgcolor: "#211A35",
        height: "100%",
      }}
    >
      <CardContent
        sx={{
          px: 3,
          py: 3,
        }}
      >
        {component}
      </CardContent>
    </Card>
  );
}

function Rejected({ error_comment }) {
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconStatus status="error" />
          <Typography sx={{ ml: 1 }} textAlign="center" variant="h6">
            Заявка отклонена
          </Typography>
        </Box>
        <Typography sx={{ mt: 2 }} textAlign="center" variant="body2">
          {error_comment}
        </Typography>
      </Box>
    </>
  );
}

function Success() {
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconStatus />
          <Typography sx={{ ml: 1 }} textAlign="center" variant="h6">
            Заявка выполнена
          </Typography>
        </Box>
        <Typography sx={{ mt: 2 }} textAlign="center" variant="body2">
          Благодарим Вас за обмен!
        </Typography>
      </Box>
    </>
  );
}

function ConfirmPayment() {
  return (
    <>
      <Typography textAlign="center" variant="h6">
        Средства получены, ожидайте поступления по вашим реквизитам.
      </Typography>
      <Box
        sx={{
          pt: 2,
          color: "#5EC4D1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon fontSize="55px" icon="svg-spinners:pulse-3" />
      </Box>
    </>
  );
}

function CheckPayment() {
  return (
    <>
      <Typography textAlign="center" variant="h6">
        Идет проверка полученных средств.
      </Typography>
      <Box
        sx={{
          pt: 2,
          color: "#5EC4D1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon fontSize="55px" icon="svg-spinners:pulse-3" />
      </Box>
    </>
  );
}

function Pay({ payment_requisites = [], payment_sum, buy_code }) {
  return (
    <>
      <Field name="Сумма" value={payment_sum} code={buy_code} />
      <Typography variant="body2" sx={{ my: 2 }}>
        Счет для оплаты:
      </Typography>
      {payment_requisites.map((f) => {
        return <Field name={f.name} value={f.value} />;
      })}
    </>
  );
}

function VerifyCard() {
  return (
    <>
      <Typography textAlign="center" variant="h6">
        Производится проверка карты.
      </Typography>
      <Box
        sx={{
          pt: 2,
          color: "#5EC4D1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon fontSize="55px" icon="svg-spinners:pulse-3" />
      </Box>
    </>
  );
}

function Field({ name, value, code }) {
  return (
    <Box sx={{ mt: 1 }}>
      <Typography variant="body2" fontWeight="400">
        {name}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{ wordBreak: "break-word" }}
          variant="body1"
          fontWeight="bold"
        >
          {value} {code}
        </Typography>
        <Copy text={value} />
      </Box>
    </Box>
  );
}

function IconStatus({ status }) {
  return (
    <Box
      sx={{
        width: "35px",
        height: "35px",
        borderRadius: 2,
        bgcolor: status == "error" ? "#EE3275" : "#5EC4D1",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon
        fontSize="24px"
        icon={status == "error" ? "mi:circle-error" : "mi:circle-check"}
      />
    </Box>
  );
}
export default StatusInfoCard;
