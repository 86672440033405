import { useState, useEffect } from "react";

// Mui
import {
  Box,
  Container,
  Typography,
  Card,
  TextField,
  Button,
  CircularProgress,
  CardContent,
  FormHelperText,
  FormControl,
  Alert,
} from "@mui/material";
import axios from "axios";
// Hooks
import { useForm, Controller } from "react-hook-form";
// Routing
import { Link } from "react-router-dom";

import { Icon } from "@iconify/react";

function ResetPassword() {
  let [email, setEmail] = useState("");
  return (
    <Box sx={{ mt: 6 }}>
      <Container maxWidth="xs">
        <Typography textAlign="center" variant="h4">
          Восстановление пароля
        </Typography>
        <Box sx={{ my: 4 }}>
          <Card
            sx={{
              border: "2px solid #3A3255",
              borderRadius: 4,
              bgcolor: "#211A35",
              height: "100%",
              mt: 4,
            }}
          >
            <CardContent
              sx={{
                p: "32px 24px !important",
              }}
            >
              {email ? (
                <SuccessContent email={email} />
              ) : (
                <RequestContent setEmail={setEmail} />
              )}
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

function RequestContent({ setEmail }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onSubmit", defaultValues: { email: "" } });
  let [isSubmitting, setSubmiting] = useState(false);
  const onSubmit = handleSubmit((data) => {
    setSubmiting(true);
    axios
      .get("/account/recovery-password", {
        params: {
          email: data.email,
        },
      })
      .then(() => {
        setEmail(data.email);
      })
      .catch((e) => {})
      .finally(() => {
        setSubmiting(false);
      });
  });

  return (
    <Box noValidate component="form" onSubmit={onSubmit}>
      <CardContent sx={{ p: "8px !important" }}>
        <Typography sx={{ mb: 3 }} textAlign="center">
          Укажите Ваш e-mail, на него будет выслана ссылка для восстановления
          пароля
        </Typography>
      </CardContent>

      <Controller
        name={"email"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              error={Boolean(errors.email?.message)}
              {...register("email", {
                required: "Введите email!",
                maxLength: 50,
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: "Не верный Email!",
                },
              })}
              fullWidth
              autoFocus
              // helperText={errors.email?.message}
              placeholder="E-mail"
              name="email"
              type="email"
              variant="outlined"
              onChange={onChange}
              value={value}
            />
            {errors.email?.message && (
              <FormHelperText error color="error">
                {errors.email?.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Button
        sx={{
          mt: 3,
        }}
        color="primary"
        startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
        disabled={isSubmitting}
        type="submit"
        fullWidth
        variant="contained"
      >
        Сбросить пароль
      </Button>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Typography
          variant="body2"
          sx={{ ml: 1, textDecoration: "none" }}
          component={Link}
          to="/login"
          color="primary.link"
        >
          Вернуться ко входу
        </Typography>
      </Box>
    </Box>
  );
}

function SuccessContent({ email }) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      <Icon color="#886EEF" fontSize="70px" icon="ri:mail-send-line" />
      <Typography textAlign='center' sx={{ mt: 2, mb: 1 }} variant="body1">
        Мы отправили вам письмо на <b>{email}</b>.
      </Typography>
      <Typography textAlign='center' variant="body2">
        Для сброса пароля перейдите по ссылке из письма и установите новый пароль.
      </Typography>
    </Box>
  );
}

export default ResetPassword;
