import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import Countdown from "react-countdown";

import { Icon } from "@iconify/react";

function Rate({ style = {}, sell, buy, status_name, expire_time }) {
  let show_timer =
    status_name === "client_payment" ||
    status_name === "check_client_payment" ||
    status_name === "verify_card";

  return (
    <Box
      sx={{
        border: "1.5px solid #5EC4D1",
        borderRadius: 4,
        bgcolor: "#211A35",
        ...style,
      }}
    >
      <CardContent
        sx={{
          p: "12px 18px !important",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="body1">Курс:</Typography>
          <Typography fontWeight="600" variant="h6">
            {sell} = {buy}
          </Typography>
        </Box>
        {show_timer && <Timer expire_time={expire_time} />}
      </CardContent>
    </Box>
  );
}
function Timer({ expire_time }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          "& .svg": {
            color: "#5EC4D1",
          },
          display: "inline-flex",
          alignItems: "center",
          color: "#5EC4D1",
        }}
      >
        <Icon fontSize="28px" icon="fluent:timer-48-regular" />
      </Box>
      <Typography fontWeight="bold" sx={{ ml: 1 }} variant="h6">
        <Countdown date={expire_time} renderer={renderer} />
      </Typography>
    </Box>
  );
}

const renderer = ({ minutes, seconds, completed }) => {
  if (completed) {
    return null;
  } else {
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  }
};

export default Rate;
