import { useState, useEffect } from "react";

import { Card, CardContent, Typography, Box, Grid } from "@mui/material";
import moment from "moment";
import Color from "color";

import axios from "axios";

import { useNavigate } from "react-router-dom";

import { Empty } from "components/Emty";

function Orders() {
  let [list, setList] = useState([]);
  let [loading, setLoading] = useState(true);

  let navigate = useNavigate();

  useEffect(() => {
    axios.get("/order/history").then((r) => {
      setList(r.data);
      setLoading(false);
    });
  }, []);

  if (loading) return null;

  return (
    <Box>
      <Typography variant="h6" fontWeight="bold">
        Заказы
      </Typography>
      <Box sx={{ mt: 3, maxHeight: "70vh" }}>
        {list.length ? (
          list.map((o) => {
            return <OrderCard navigate={navigate} key={o._id} data={o} />;
          })
        ) : (
          <Empty text="Нет заказов" />
        )}
      </Box>
    </Box>
  );
}

function OrderCard({ data, navigate }) {
  return (
    <Box
      onClick={() => window.open("/order?hid=" + data.hash, "_blank")}
      sx={{
        mb: 2,
        // bgcolor: "#1D1730",
        borderRadius: 3,
        cursor: "pointer",
        transition: "all 0.2s",
        border: "2px solid #3A3255",
        // borderRadius: 4,
        bgcolor: "#211A35",
        // height: "100%",
        "&:hover": {
          bgcolor: Color("#1D1730").lighten(0.2).hex(),
        },
      }}
    >
      <CardContent sx={{ p: "8px 18px !important" }}>
        <Grid alignItems="center" container spacing={1}>
          <Grid item xs={12} md={7}>
            <Typography variant="body1">
              {data.sell_info.name}({data.sell_info.code}) на{" "}
              {data.buy_info.name}({data.buy_info.code})
            </Typography>
          </Grid>
          <Grid item xs={6} md={2.5}>
            <Box>
              <Typography variant="body1">
                {data.buy_info.amount} {data.buy_info.code}
              </Typography>
              <Typography
                sx={{ color: "#9D94B5", fontSize: "14px" }}
                variant="body2"
              >
                {moment(data.created_at).format("HH:mm DD.MM.YYYY")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={2.5}>
            <Typography
              sx={{
                fontSize: "15px",
                color: getStatusName(data.status_name)?.color,
              }}
              textAlign="right"
            >
              {getStatusName(data.status_name)?.name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
}

function getStatusName(name) {
  switch (name) {
    case "successfully":
      return {
        color: "#5EC4D1",
        name: "Выполнена",
      };
    case "rejected":
      return {
        color: "#EE3275",
        name: "Не выполнена",
      };
    default:
      return {
        color: "#886EEF",
        name: "В процессе",
      };
  }
}

// verify_card
// client_payment check_client_payment confirm_client_payment
// successfully  rejected

function getStatusColor(name) {
  switch (name) {
    case "error":
      return "#EE3275";
    case "success":
      return "#5EC4D1";
    default:
      return "#886EEF";
  }
}

export default Orders;
