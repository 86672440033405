import { Icon } from "@iconify/react";
import { Box, Typography } from "@mui/material";

function Empty({ text }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        color: "white",
      }}
    >
      <Box sx={{ color: "#E9E1FF" }}>
        <Icon fontSize="62px" icon="lucide:list-x" />
      </Box>
      <Typography sx={{color:  "#E9E1FF"}} textAlign="center" fontSize="18px">
        {text}
      </Typography>
    </Box>
  );
}

export { Empty };
