import { useEffect, useState } from "react";
// Mui
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";

// Icon
import { Icon } from "@iconify/react";

// Store
import { observer } from "mobx-react-lite";
import AccStore from "store/account";

// axios
import axios from "axios";

// Notify
import { toast } from "react-toastify";
import customToast from "customToast";

// Modules
import { ResetMailModal } from "modules/ResetMailModal/ResetMailModal";

function Profile() {
  // Calc

  let account_data = AccStore.account;
  let telegram_nickname = account_data?.telegram_nickname;
  let email_verified = account_data?.email_verified;
  let client_email = account_data?.client_email;

  // States
  let [showResetMail, setShowResetMail] = useState(false);
  let [telegramNickname, setTelegramNickname] = useState(telegram_nickname);

  useEffect(() => {
    setTelegramNickname(telegram_nickname);
  }, [telegram_nickname]);

  let telegramNicknameChanged = telegramNickname !== telegram_nickname;

  const resetBtnHandler = () => {
    setShowResetMail(true);
  };

  const saveTelegram = () => {
    axios
      .put("/account", {
        telegramNickname: telegramNickname,
      })
      .then((res) => {
        AccStore.setChangeAccountData("telegram_nickname", telegramNickname);
        customToast.success("Telegram изменен");
      });
  };

  if (!email_verified) return <UnVerifiedView email={client_email} />;

  return (
    <Box>
      <ResetMailModal
        email={client_email}
        open={showResetMail}
        handleClose={() => {
          setShowResetMail(false);
        }}
      />

      <Box>
        <Box>
          <Typography variant="h6" fontWeight="bold">
            Профиль
          </Typography>
          <CardContent
            sx={{
              borderRadius: 3,
              border: "2px solid #3A3255",
              bgcolor: "#211A35",
              mt: 3,
            }}
          >
            <Grid sx={{ mt: 1 }} alignItems="center" container spacing={1}>
              <Grid item xs={4} md={2}>
                <Typography fontWeight={500} variant="body1">
                  Email
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                md={10}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography sx={{ mr: 1 }} fontWeight={600} variant="body1">
                  {client_email}
                </Typography>
                <Icon
                  fontSize="24px"
                  color={email_verified ? "#5EC4D1" : "#EE3275"}
                  icon={
                    email_verified ? "dashicons:saved" : "ic:baseline-close"
                  }
                />
              </Grid>
            </Grid>
            <Grid alignItems="center" sx={{ mt: 1 }} container spacing={1}>
              <Grid item xs={4} md={2}>
                <Typography fontWeight={500} variant="body1">
                  Telegram
                </Typography>
              </Grid>
              <Grid item xs={8} md={10}>
                <TextField
                  InputProps={{
                    endAdornment: telegramNicknameChanged ? (
                      <InputAdornment position="end">
                        <IconButton
                          color="primary.main"
                          aria-label="toggle password visibility"
                          onClick={saveTelegram}
                          sx={{ color: "#5EC4D1" }}
                          edge="end"
                        >
                          <Icon icon="dashicons:saved" />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                  placeholder="Опционально"
                  name="telegram"
                  type="text"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setTelegramNickname(e.target.value)}
                  value={telegramNickname}
                />
              </Grid>
            </Grid>
            <Grid sx={{ mt: 1 }} alignItems="center" container spacing={1}>
              <Grid item xs={4} md={2}>
                <Typography fontWeight={500} variant="body1">
                  Пароль
                </Typography>
              </Grid>
              <Grid item xs={8} md={10}>
                <Box
                  onClick={resetBtnHandler}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: (t) => t.palette.primary.link,
                    cursor: "pointer",
                    // fontSize: "18px",
                    transition: "all 0.2s",
                    "&:hover": {
                      color: (t) => t.palette.primary.lightLink,
                    },
                  }}
                >
                  <Icon fontSize="24px" icon="akar-icons:edit" />
                  <Typography
                    sx={{ color: "inherit", ml: 1 }}
                    fontWeight={600}
                    variant="body1"
                  >
                    Изменить
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Box>
    </Box>
  );
}

function UnVerifiedView({ email }) {
  let [submitting, setSubmitting] = useState(false);

  const resendHandler = () => {
    setSubmitting(true);
    axios
      .post("/account/resend-verify-mail")
      .then((res) => {
        customToast.success("Письмо отправлено на Email");
      })
      .catch((e) => {})
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Card
      sx={{
        border: "2px solid #3A3255",
        borderRadius: 4,
        bgcolor: "#211A35",
      }}
    >
      <CardContent
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <Typography textAlign="center" variant="h6" sx={{ mb: 1 }}>
          {` Аккаунт ${email} не активирован`}
        </Typography>
        <Typography sx={{ mb: 3 }} textAlign="center" variant="body2">
          Адрес почты не подтвержден
        </Typography>
        <Button
          endIcon={submitting ? <CircularProgress /> : null}
          onClick={resendHandler}
          // sx={{ mt: 4 }}
          variant="contained"
        >
          Отправить снова
        </Button>
      </CardContent>
    </Card>
  );
}

export default observer(Profile);
