import { useState } from "react";

import {
  Box,
  Typography,
  Grid,
  CardContent,
  Card,
  Button,
} from "@mui/material";

import axios from "axios";

import { useDropzone } from "react-dropzone";

import ei from "assets/images/examp-verify.png";

// let ei = '';

function VerifyCard({
  sx = {},
  setImage,
  image,
  submitHandler,
  closeHandler,
  isReverify = false,
  comment,
}) {
  const onSubmit = () => {
    submitHandler();
    closeHandler();
  };

  return (
    <CardContent>
      <Typography sx={{ mt: 2, mb: 2 }} textAlign="center" variant="h5">
        {isReverify ? "Повторная верификация карты" : "Верификация карты"}
      </Typography>
      {isReverify && (
        <Typography
          sx={{ mb: 2, color: "#EE3275" }}
          textAlign="center"
          variant="body2"
        >
          {comment}
        </Typography>
      )}

      {!isReverify && (
        <Typography
          variant="body2"
          textAlign="center"
          fontWeight="bold"
          sx={{ mb: 2, color: "#E9E1FF", mb: 5 }}
        >
          Для осуществления обмена требуется проверка банковской карты. Данная
          процедура предназначена для того, чтобы обезопасить Ваши денежные
          средства от мошеннических действий. В среднем проверка карты занимает
          3-8 минут.
        </Typography>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <img alt="image" src={ei} style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography
            fontWeight="bold"
            variant="body2"
            sx={{
              color: "#E9E1FF",
            }}
          >
            Загрузите фото лицевой стороны карты на фоне сделки. Можно закрыть
            данные карты кроме цифр номера карты.
          </Typography>
        </Grid>
      </Grid>
      <Dropping setImageName={setImage} />
      {!isReverify && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            onClick={onSubmit}
            disabled={!image}
            variant="contained"
            sx={{ mr: 1 }}
          >
            Верифицировать
          </Button>
          <Button onClick={closeHandler} sx={{ ml: 1 }} variant="outlined">
            Отменить обмен
          </Button>
        </Box>
      )}
    </CardContent>
  );
}

function Dropping({ style = {}, setImageName }) {
  const [files, setFiles] = useState([]);

  // ** Hook
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".gif"],
    },
    onDrop: (acceptedFiles) => {
      let file = acceptedFiles[0];

      const formData = new FormData();
      formData.append("cardImage", file);

      axios
        .post("/card-upload-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          setImageName(data.name);
        });

      setFiles(acceptedFiles.map((file) => Object.assign(file)));
    },
  });

  //   const handleLinkClick = (event) => {
  //     event.preventDefault();
  //   };

  const img = files.map((file) => (
    <Typography textAlign="center">{file.name}</Typography>
  ));

  let cardStyle = {
    border: "2px dashed #E9E1FF",
    borderRadius: "12px",
    px: 3,
    py: 2,
    mt: 4,
    ...style,
  };

  return (
    <Card {...getRootProps({ className: "dropzone" })} sx={cardStyle}>
      <input {...getInputProps()} />
      {files.length ? (
        img
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              ml: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography fontWeight="bold" variant="body1" textAlign="center">
              Выберите файл или перетащите в Эту область
            </Typography>
            <Typography
              sx={{ mt: 1, color: "#E9E1FF" }}
              variant="body2"
              textAlign="center"
            >
              Формат: JPG, PNG
            </Typography>
            <Typography
              sx={{ mt: 1, color: "#E9E1FF" }}
              variant="body2"
              textAlign="center"
            >
              Максимальный размер: 5 МБ
            </Typography>
          </Box>
        </Box>
      )}
    </Card>
  );
}

export default VerifyCard;
