import { Icon } from "@iconify/react";
import { Box, Divider, Typography } from "@mui/material";

export default function Stepper({ style = {}, status_name }) {
  let list = [];

  switch (status_name) {
    case "client_payment":
    case "verify_card":
      list = ["w", "", ""];
      break;
    case "check_client_payment":
      list = ["s", "w", ""];
      break;
    case "confirm_client_payment":
      list = ["s", "s", "w"];
      break;
    case "successfully":
      list = ["s", "s", "s"];
      break;
    case "rejected":
    case "reverify_card":
      list = ["e", "e", "e"];
      break;
    default:
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...style,
      }}
    >
      <Step status={list[0]} name="Перевод" />
      <Divider sx={{ flexGrow: 1, mx: 2 }} />
      <Step status={list[1]} name="Проверка платежа" />
      <Divider sx={{ flexGrow: 1, mx: 2 }} />
      <Step status={list[2]} name="Итог" />
    </Box>
  );
}

function Step({ name, status }) {
  let color = "";
  let icon = "";

  switch (status) {
    case "w":
      color = "#5EC4D1";
      icon = "svg-spinners:90-ring";
      break;
    case "s":
      color = "#5EC4D1";
      icon = "mdi:success-circle-outline";
      break;
    case "e":
      color = "#EE3275";
      icon = "mdi:close-circle-outline";
      break;
    default:
      color = "#9D94B5";
      icon = "mdi:circle-outline";
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Icon fontSize={"28px"} style={{ color }} icon={icon} />
      <Typography sx={{ ml: 1 }} variant="body1" fontWeight="600">
        {name}
      </Typography>
    </Box>
  );
}
