import { useState, useRef, useLayoutEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  IconButton,
  Popover,
  Popper,
  Drawer,
  FormHelperText,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Icon } from "@iconify/react";
import InputMask from "react-input-mask";
import { useForm, Controller } from "react-hook-form";

import FieldList from "./FieldList";

import SearchAndSelectCurrency from "./SearchAndSelectCurrency";

function Part({
  currencies,
  title,
  data,
  setId,
  dir,
  fields,
  amount,
  setAmount,
  errorAmount,
  errors,
  register,
  getValues,
  setValue,
  control,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef();
  const containerRef = useRef();
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setWidth(containerRef.current.clientWidth);
  }, []);

  const handleClick = () => {
    setAnchorEl(anchorEl ? null : anchorRef.current);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const theme = useTheme();
  const isDesk = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Card
      ref={containerRef}
      sx={{
        border: "2px solid #3A3255",
        borderRadius: 4,
        bgcolor: "#211A35",
        height: "100%",
      }}
    >
      <CardContent
        sx={{
          px: 4,
          py: 2,
        }}
      >
        <Typography color="#D9D3E8" sx={{ mb: 2 }} variant="h6">
          {title} {data?.name} ({data?.code})
        </Typography>
        <TextField
          error={!!errorAmount}
          ref={anchorRef}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
          aria-describedby={id}
          InputProps={{
            endAdornment: (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ mr: 1 }} variant="h6" fontWeight="300">
                  {data?.code}
                </Typography>
                <IconButton
                  onClick={handleClick}
                  sx={{ borderRadius: 0, color: "#8779AE94" }}
                >
                  <img height="40px" width="40px" src={data?.icon} />
                  <Icon
                    style={{ "margin-left": "10px" }}
                    fontSize="14px"
                    icon={
                      Boolean(anchorEl)
                        ? "ep:arrow-up-bold"
                        : "ep:arrow-down-bold"
                    }
                  />
                </IconButton>
              </Box>
            ),
          }}
          fullWidth
          placeholder={
            dir == "sell"
              ? `Максимум ${data?.reserve_value}`
              : `Минимум ${data?.min_sum}`
          }
        />
        {errorAmount && (
          <FormHelperText sx={{ fontSize: "14px", fontWeight: "500" }} error>
            {errorAmount}
          </FormHelperText>
        )}

        {isDesk ? (
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClick}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Card
              sx={{
                width: width,
                border: "2px solid #3A3255",
                borderTopWidth: "0px",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                bgcolor: "#211A35",
                boxShadow: "none",
              }}
            >
              <SearchAndSelectCurrency
                setId={setId}
                cId={data?.id}
                currencies={currencies}
                handleClose={handleClick}
                isDesk
              />
            </Card>
          </Popover>
        ) : (
          <Drawer
            PaperProps={{
              sx: {
                border: "2px solid #3A3255",
                borderRadius: 4,
                bgcolor: "#211A35",
                minHeight: "80%",
              },
            }}
            anchor={"bottom"}
            open={open}
            onClose={handleClick}
          >
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography color="#D9D3E8" variant="h6">
                {title}
              </Typography>
              <IconButton onClick={handleClick} sx={{ color: "white" }}>
                <Icon icon="material-symbols:close" />
              </IconButton>
            </CardContent>
            <Divider />
            <SearchAndSelectCurrency
              setId={setId}
              cId={data?.id}
              currencies={currencies}
              handleClose={handleClick}
            />
          </Drawer>
        )}

        <FieldList
          list={fields}
          errors={errors}
          control={control}
          register={register}
        />
      </CardContent>
    </Card>
  );
}

export default Part;
