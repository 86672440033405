import { useState, useEffect } from "react";

import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
} from "@mui/material";

import API from "configs/api";

function Cooperation() {
  const [select, setSelect] = useState("referral");
  // partner

  return (
    <Box sx={{ my: 4 }}>
      <Container sx={{ mt: 5 }} maxWidth="md">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 4,
          }}
        >
          <Typography
            color={select == "referral" && "primary.link"}
            onClick={() => setSelect("referral")}
            sx={{
              mx: 2,
              cursor: "pointer",
              transition: "0.5s all",
            }}
            variant="h6"
          >
            Реферальная программа
          </Typography>
          <Typography
            color={select == "monitoring" && "primary.link"}
            onClick={() => setSelect("monitoring")}
            variant="h6"
            sx={{
              mx: 2,

              cursor: "pointer",
              transition: "0.5s all",
            }}
          >
            Мониторинг
          </Typography>
        </Box>
        {select == "monitoring" && <MonitoringContent />}
        {select == "referral" && <ReferralContent />}
      </Container>
    </Box>
  );
}

function ReferralContent() {
  return (
    <Box>
      <Card
        sx={{
          border: "2px solid #3A3255",
          borderRadius: 4,
          bgcolor: "#211A35",
          height: "100%",
          mt: 4,
        }}
      >
        <CardContent
          sx={{
            p: "16px 24px !important",
          }}
        >
          <Typography textAlign="center" variant="body2">
            Посоветуйте наш сервис друзьям и партнерам и зарабатывайте вместе с
            нами! Ваша реферальная ссылка находится в личном кабинете, в разделе
            «Реферальная программа», скопируйте её и поделитесь с друзьями! Для
            вашего удобства в личном кабинете хранится история реферальных
            начислений.
          </Typography>
          <Typography sx={{ mt: 2 }} textAlign="center" variant="body2">
            В случае если реферальная заявка прибыльная, то мы делимся с вами <b style={{ fontWeight: 600, fontSize: '18px' }}>10%</b> от нашего
            дохода.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

function MonitoringContent() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Card
        sx={{
          border: "2px solid #3A3255",
          borderRadius: 4,
          bgcolor: "#211A35",
          height: "100%",
        }}
      >
        <CardContent
          sx={{
            p: "16px 24px !important",
          }}
        >
          <Typography sx={{ mb: 2 }} textAlign="center" variant="body2">
            Для того чтобы иметь возможность мониторинга наших обменных курсов и
            резервов, вы можете воспользоваться ссылкой ниже в формате XML.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => {
                window.open(API.COURSES_LINK);
              }}
              variant="contained"
            >
              Курсы XML
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Cooperation;
