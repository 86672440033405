import { useState } from "react";

// Mui
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Card,
  CircularProgress,
  CardContent,
  FormHelperText,
  FormControlLabel,
  FormControl,
} from "@mui/material";

// Hooks
import { useForm, Controller } from "react-hook-form";
// Routing
import { Link, useNavigate } from "react-router-dom";

// fetch
import axios from "axios";

// Store
import AccStore from "store/account";

function Register() {
  const navigate = useNavigate();

  let rid = AccStore.rid;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
      refCode: rid || "",
    },
  });
  let [isSubmitting, setSubmiting] = useState(false);
  const onSubmit = handleSubmit((data) => {
    setSubmiting(true);
    axios
      .post("/account/sign-up", data)
      .then((res) => {
        navigate("/register/confirm");
        // AccStore.setAccount(res.data.account);
        // localStorage.setItem("access_token", res.data.access_token);
        //
      })
      .catch((e) => {})
      .finally(() => {
        setSubmiting(false);
      });
  });

  return (
    <Box sx={{ mt: 6 }}>
      <Container maxWidth="xs">
        <Typography textAlign="center" variant="h4">
          Создание учетной записи
        </Typography>
        <Box sx={{ my: 4 }}>
          <Card
            sx={{
              border: "2px solid #3A3255",
              borderRadius: 4,
              bgcolor: "#211A35",
              height: "100%",
              mt: 4,
            }}
          >
            <CardContent
              sx={{
                p: "32px 24px !important",
              }}
              noValidate
              onSubmit={onSubmit}
            >
              <Controller
                name={"email"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      error={Boolean(errors.email?.message)}
                      {...register("email", {
                        required: "Введите email!",
                        maxLength: 50,
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Не верный Email!",
                        },
                      })}
                      fullWidth
                      autoFocus
                      placeholder="E-mail"
                      name="email"
                      type="email"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                    />
                    {errors.email?.message && (
                      <FormHelperText error color="error">
                        {errors.email?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                name={"password"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      {...register("password", {
                        required: "Обязательное поле!",
                        minLength: {
                          value: 6,
                          message: "Мин.  6 символов!",
                        },
                        maxLength: 100,
                      })}
                      error={Boolean(errors.password?.message)}
                      fullWidth
                      // helperText={errors.password?.message}
                      placeholder="Пароль"
                      name="password"
                      type="password"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                    />
                    {errors.password?.message && (
                      <FormHelperText error color="error">
                        {errors.password?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                name={"refCode"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      {...register("refCode", {
                        maxLength: 100,
                      })}
                      error={Boolean(errors.refCode?.message)}
                      fullWidth
                      sx={{ mb: 1 }}
                      // helperText={errors.refCode?.message}
                      placeholder="Реферальный код (опционально)"
                      name="refCode"
                      type="text"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                    />
                    {errors.refCode?.message && (
                      <FormHelperText error color="error">
                        {errors.refCode?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />

              <Button
                sx={{
                  mt: 3,
                }}
                color="primary"
                onClick={onSubmit}
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                type="submit"
                fullWidth
                variant="contained"
              >
                Зарегистрироваться
              </Button>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>Уже есть аккаунт?</Typography>
                <Typography
                  variant="body2"
                  sx={{ ml: 1, textDecoration: "none" }}
                  component={Link}
                  to="/login"
                  color="primary.link"
                >
                  Войти
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

export default Register;
