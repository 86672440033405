import { useState } from "react";

import {
  TextField,
  Card,
  CardContent,
  Box,
  Tab,
  Tabs,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { Icon } from "@iconify/react";

let icon = "https://cdn-icons-png.flaticon.com/512/5968/5968260.png";

const tl = [
  {
    name: "Все",
    value: "",
  },
  {
    name: "Крипто",
    value: "CRYPTO",
  },
  {
    name: "Фиат",
    value: "FIAT",
  },
];

function SearchAndSelectCurrency({
  currencies,
  setId,
  cId,
  handleClose,
  isDesk,
}) {
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let filtList = [...currencies];

  if (value) {
    filtList = filtList.filter((c) => c.currency_type == value);
  }
  if (search) {
    filtList = filtList.filter((c) => {
      let s = search.toLowerCase();
      return (
        c.name.toLowerCase().includes(s) || c.code.toLowerCase().includes(s)
      );
    });
  }

  return (
    <Box>
      <CardContent>
        <TextField
          size="small"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          fullWidth
          placeholder="Поиск..."
        />
      </CardContent>
      <Box sx={{ borderBottom: 1, borderColor: "#382D55" }}>
        <Tabs value={value} onChange={handleChange} sx={{ width: "100%" }}>
          {tl.map((t) => (
            <Tab value={t.value} label={t.name} />
          ))}
        </Tabs>
      </Box>

      <List
        sx={{ maxHeight: isDesk ? "30vh" : undefined, overflowY: "scroll" }}
      >
        {filtList.map((c) => {
          return (
            <CurrencyCard
              handl={(i) => {
                setId(i);
                handleClose();
              }}
              isSelect={cId == c.id}
              key={c.id}
              c={c}
            />
          );
        })}
      </List>
    </Box>
  );
}

function CurrencyCard({ c, isSelect, handl }) {
  return (
    <ListItemButton onClick={() => handl(c.id)} selected={isSelect}>
      <ListItemIcon>
        <img height="40px" width="40px" src={c.icon} />
      </ListItemIcon>
      <ListItemText primary={`${c.name} (${c.code})`} />
      {isSelect && (
        <Box
          sx={{
            width: "29px",
            height: "29px",
            bgcolor: "#5EC4D1",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            color: "white",
          }}
        >
          <Icon fontSize="14px" icon="mdi:success" />
        </Box>
      )}
    </ListItemButton>
  );
}

export default SearchAndSelectCurrency;
