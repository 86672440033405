import { makeObservable, observable, action } from "mobx";

class Profile {
  account = null;
  refCode = null;
  isAuth = false;
  rid = "";

  constructor() {
    makeObservable(this, {
      rid: observable,
      account: observable,
      refCode: observable,
      isAuth: observable,
      setAccount: action,
      setRefCode: action,
      logoutAccount: action,
      setRid: action,
    });
  }

  setAccount(data) {
    this.account = data;
    this.isAuth = true;
  }
  setChangeAccountData(name, value) {
    let newAccountData = Object.assign({}, this.account, { [name]: value });
    this.account = newAccountData;
  }

  setRid(code) {
    this.rid = code;
  }

  setRefCode(code) {
    this.refCode = code;
  }

  logoutAccount() {
    this.isAuth = false;
    this.account = null;
    localStorage.removeItem("access_token");
  }
}

const profileStore = new Profile();

export default profileStore;
