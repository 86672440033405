const SERVER =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://api.hermex.cc";

const config = {
  API: `${SERVER}/api/ru`,
  SERVER,
  API_IMAGES: `${SERVER}/images`,
  DOMAIN: "https://hermex.cc",
  COURSES_LINK: "https://hermex.cc/courses-info.xml",
  TELEGRAM_LINK: "https://t.me/",
  EMAIL: 'helphermexсс@gmail.com'
};


export default config;
