import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  IconButton,
  Grid,
} from "@mui/material";

import { Icon } from "@iconify/react";

function Reqs({ style = {}, sell_info, buy_info }) {
  return (
    <Grid
      sx={{ ...style }}
      container
      spacing={2}
      justifyContent="space-between"
    >
      <Grid item xs={12} md={5.5}>
        <Card
          sx={{
            border: "2px solid #3A3255",
            borderRadius: 4,
            bgcolor: "#211A35",
            height: "100%",
          }}
        >
          <CardContent
            sx={{
              px: 3,
              py: 3,
            }}
          >
            <Cur
              name={sell_info.name}
              code={sell_info.code}
              sum={sell_info.sum}
              image={sell_info.image}
            />
            <Box sx={{ mt: 2 }}>
              {sell_info.fields.map((f) => {
                return <Field name={f.name} value={f.value} />;
              })}
            </Box>
            {sell_info.info && (
              <Typography sx={{ fontSize: "12px", mt: 2, color: '#5EC4D1'}} variant="body1">
                {sell_info.info}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} item md={1} alignSelf="center">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: 2,
            color: "white",
          }}
        >
          <Icon fontSize="28px" icon="jam:refresh-reverse" />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.5}>
        <Card
          sx={{
            border: "2px solid #3A3255",
            borderRadius: 4,
            bgcolor: "#211A35",
            height: "100%",
          }}
        >
          <CardContent
            sx={{
              px: 3,
              py: 3,
            }}
          >
            <Cur
              name={buy_info.name}
              code={buy_info.code}
              sum={buy_info.sum}
              image={buy_info.image}
            />
            <Box sx={{ mt: 2 }}>
              {buy_info.fields.map((f) => {
                return <Field name={f.name} value={f.value} />;
              })}
            </Box>
            {buy_info.info && (
              <Typography sx={{ fontSize: "12px", mt: 2, color: '#5EC4D1'}} variant="body1">
                {buy_info.info}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

function Cur({ name, code, sum, image }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <img height="40px" width="40px" src={image} />
      <Box sx={{ ml: 1.5 }}>
        <Typography variant="h6">
          {name} ({code})
        </Typography>
        <Typography variant="body2" fontWeight="600">
          {sum} {code}
        </Typography>
      </Box>
    </Box>
  );
}

function Field({ name, value }) {
  return (
    <Box sx={{ mt: 1 }}>
      <Typography variant="body1" fontWeight="400">
        {name}
      </Typography>
      <Typography variant="body1" fontWeight="bold">
        {value}
      </Typography>
    </Box>
  );
}

export default Reqs;
