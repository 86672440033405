import { Box, IconButton } from "@mui/material";

import Logo from "./Logo/Logo";

// Icon
import { Icon } from "@iconify/react";
import Color from "color";

function HeaderMenu({ style = {}, closeHandler = () => {} }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", ...style }}>
      <Logo handler={closeHandler} />
      <IconButton
        sx={{
          color: "#FFFFFF",
          ml: 1,
          "&:hover": {
            color: Color("#FFFFFF").alpha(0.8),
            bgcolor: "transparent",
          },
        }}
        onClick={closeHandler}
      >
        <Icon fontSize="26px" icon="mingcute:close-fill" />
      </IconButton>
    </Box>
  );
}

export default HeaderMenu;
