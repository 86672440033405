//

import { Icon } from "@iconify/react";
import {
  Box,
  CardContent,
  Grid,
  Card,
  IconButton,
  Typography,
  Button,
  FormHelperText,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Color from "color";
import { Empty } from "components/Emty";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";

import InputMask from "react-input-mask";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import customToast from "customToast";

function Withdraw() {
  let [data, setData] = useState(null);
  useEffect(() => {
    axios.get("/withdraw").then((r) => {
      setData(r.data);
    });
  }, []);
  const navigate = useNavigate();

  if (!data) return null;

  const addHistory = (i) => {
    setData((d) => {
      return Object.assign({}, d, { history: [i, ...d.history] });
    });
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={() => navigate("/account/referrals")}
          sx={{
            bgcolor: "#382D55",
            borderRadius: 3,
            color: "white",
            "&:hover": {
              color: "white",
              bgcolor: Color("#382D55").lighten(0.3).hex(),
            },
          }}
        >
          <Icon fontSize="20px" icon="pajamas:go-back" />
        </IconButton>
        <Typography sx={{ ml: 1 }} variant="h6" fontWeight="bold">
          Заказ выплаты
        </Typography>
      </Box>

      <Grid sx={{ mt: 0 }} container spacing={4}>
        <Grid item xs={12} md={6}>
          <FormCreate
            addHistory={addHistory}
            balance={data.balance}
            currencies={data.currencies}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <History list={data.history} />
        </Grid>
      </Grid>
    </Box>
  );
}

function History({ list = [] }) {
  let hasHistory = !!list.length;

  let listRender = list.map((item) => {
    return <HistoryCard key={item._id} data={item} />;
  });
  return (
    <Box>
      <Typography fontWeight="bold" variant="body1">
        История выводов
      </Typography>

      {/* empty  */}
      <Box sx={{ maxHeight: "70vh", mt: 2, overflowY: "scroll" }}>
        <Box sx={{ mt: 2, maxHeight: "40vh" }}>
          {hasHistory ? listRender : <Empty text="Нет выводов" />}
        </Box>
      </Box>
    </Box>
  );
}

function HistoryCard({data}) {
  let status_name = data.status_name;

  return (
    <Card
      sx={{
        borderRadius: 3,
        p: "12px 16px !important",
        mb: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
      }}
    >
      <Box>
        <Typography variant="body1">{data.amount} RUB</Typography>
        <Typography
          sx={{ color: getStatusName(status_name).color }}
          variant="body2"
        >
          {getStatusName(status_name).name}
        </Typography>
      </Box>
      <Typography
        sx={{ color: Color("#BBB3D0").darken(0.2).hex() }}
        variant="body2"
      >
        {moment(data.created_at).format("HH:mm DD.MM.YYYY")}
      </Typography>
    </Card>
  );
}

function getStatusName(name) {
  switch (name) {
    case "success":
      return {
        color: "#5EC4D1",
        name: "Выполнена",
      };
    case "reject":
      return {
        color: "#EE3275",
        name: "Не выполнена",
      };
    case "process":
      return {
        color: "#886EEF",
        name: "В процессе",
      };
    default:
      return {};
  }
}
function FormCreate({ currencies, balance, addHistory }) {
  let [cid, setCid] = useState(currencies[1].id);

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      sum: "",
    },
  });

  const onSubmit = handleSubmit((d) => {
    let fields = Object.assign({}, d);
    delete fields.sum;
    var list = [];
    for (let key in fields) {
      list.push({
        id: key,
        value: fields[key],
      });
    }
    let fd = Object.assign({}, { cid, sum: +d.sum, list });
    axios
      .post("/withdraw", fd)
      .then(({ data }) => {
        addHistory(data);
        customToast.success("Ожидайте обработку заявки.");
        reset();
      })
      .catch((e) => {
      });
  });

  let field_list = currencies.find((c) => c.id == cid)?.fill_fields || [];
  return (
    <Box
      sx={{
        borderRadius: 3,
        border: "2px solid #3A3255",
        bgcolor: "#211A35",
      }}
    >
      <CardContent sx={{ p: "12px 18px !important" }}>
        <Typography fontWeight="bold" sx={{ mb: 2 }} variant="body1">
          Создание заявки
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            my: 2,
            justifyContent: "space-between",
          }}
        >
          <Typography>Текущий Баланс</Typography>
          <Typography fontWeight="bold">{balance} RUB</Typography>
        </Box>

        <Box component="form" onSubmit={onSubmit}>
          <FormControl fullWidth>
            <Select
              disableUnderline
              sx={{
                bgcolor: (t) => t.palette.background.paper,
                borderRadius: 6,
                ".MuiSelect-select": {
                  px: 1.5,
                  py: 1,
                },
                "& svg": {
                  color: Color("#000").alpha(0.7),
                },
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
              MenuProps={{
                PaperProps: {
                  style: {},
                },
              }}
              renderValue={(selected) => {
                let c = currencies.find((c) => c.id == selected);
                if (!c) return null;
                return (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                      }}
                      alt={c.name}
                      src={c.icon}
                    />
                    <Typography sx={{ ml: 1 }}>{c.name}</Typography>
                  </Box>
                );
              }}
              value={cid}
              onChange={(e, v) => setCid(e.target.value)}
            >
              {currencies.map((c) => {
                return (
                  <MenuItem
                    sx={{ display: "flex", alignItems: "center" }}
                    key={c.id}
                    value={c.id}
                  >
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                      }}
                      alt={c.name}
                      src={c.icon}
                    />
                    <Typography sx={{ ml: 1 }}>{c.name}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {field_list.map((f) => {
            let validate = f.validate;
            let isMask = false;
            let maskChar = "";
            let mask = "";

            let fieldValidate = undefined;

            if (validate == "card" || validate == "phone") {
              isMask = true;

              if (validate == "card") {
                mask = "9999 9999 9999 9999";
                maskChar = " ";
                fieldValidate = (val) => {
                  let number = val.replace(/\D/g, "");
                  if (number.length < 16) {
                    return "Введите верный формат!";
                  }
                };
              }

              if (validate == "phone") {
                mask = "+7(999) 999-99-99";
                maskChar = "";
                fieldValidate = (phone) => {
                  let number = phone.replace(/\D/g, "");

                  if (number.length < 11) {
                    return "Введите верный формат!";
                  }
                };
              }
            }

            return (
              <Box key={f.id} sx={{ mt: 1 }}>
                <Typography color="#D9D3E8" sx={{ mb: 0.8 }} variant="body2">
                  {f.label}
                </Typography>
                <Controller
                  name={f.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    if (isMask) {
                      return (
                        <InputMask
                          value={value}
                          onChange={onChange}
                          placeholder={f.placeholder || ""}
                          fullWidth
                          {...register(f.id, {
                            required: "Пожалуйста, заполните это поле!",
                            maxLength: 100,
                            validate: fieldValidate,
                          })}
                          error={Boolean(errors[f.id]?.message)}
                          mask={mask}
                          maskChar={maskChar}
                          size="small"
                        >
                          {(e) => <TextField {...e} />}
                        </InputMask>
                      );
                    } else {
                      return (
                        <TextField
                          size="small"
                          value={value}
                          onChange={onChange}
                          placeholder={f.placeholder || ""}
                          fullWidth
                          {...register(f.id, {
                            required: "Пожалуйста, заполните это поле!",
                            maxLength: 100,
                            fieldValidate,
                          })}
                          error={Boolean(errors[f.id]?.message)}
                        />
                      );
                    }
                  }}
                />
                {errors[f.id]?.message && (
                  <FormHelperText
                    sx={{ fontSize: "14px", fontWeight: "500" }}
                    error
                  >
                    {errors[f.id]?.message}
                  </FormHelperText>
                )}
              </Box>
            );
          })}
          <Box sx={{ mt: 1 }}>
            <Typography color="#D9D3E8" sx={{ mb: 0.8 }} variant="body2">
              Сумма вывода
            </Typography>
            <Controller
              name={"sum"}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    size="small"
                    value={value}
                    onChange={onChange}
                    placeholder="Минимум 500 RUB"
                    fullWidth
                    type="number"
                    {...register("sum", {
                      required: "Введите сумму",
                      validate: (val) => {
                        if (val < 500) {
                          return "Минимальная сумма вывода 500 RUB";
                        }
                      },
                    })}
                    error={Boolean(errors.sum?.message)}
                  />
                );
              }}
            />
            {errors.sum?.message && (
              <FormHelperText
                sx={{ fontSize: "14px", fontWeight: "500" }}
                error
              >
                {errors.sum?.message}
              </FormHelperText>
            )}
          </Box>
          <Button type="submit" sx={{ mt: 4 }} fullWidth variant="contained">
            Заказать
          </Button>
        </Box>
      </CardContent>
    </Box>
  );
}

export { Withdraw };
