import {
  Box,
  Drawer,
  CardContent,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";

import { Icon } from "@iconify/react";

import VerifyCard from "modules/VerifyCard/VerifyCard";

function VerifyDrawer({ show, closeHandler, submitHandler, setImage, image }) {
  return (
    <Box>
      <Drawer
        PaperProps={{
          sx: {
            border: "2px solid #3A3255",
            borderRadius: 4,
            bgcolor: "#211A35",
            minHeight: "90%",
          },
        }}
        anchor={"bottom"}
        open={show}
        onClose={closeHandler}
      >
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color="#D9D3E8" variant="h6">
            Проверка карты
          </Typography>
          <IconButton onClick={closeHandler} sx={{ color: "white" }}>
            <Icon icon="material-symbols:close" />
          </IconButton>
        </CardContent>
        <Divider />
        <VerifyCard
          submitHandler={submitHandler}
          image={image}
          setImage={setImage}
          closeHandler={closeHandler}
        />
      </Drawer>
    </Box>
  );
}

export default VerifyDrawer;
