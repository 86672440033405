import React, { useState } from "react";

import { Grid, Button } from "@mui/material";

import { useNavigate } from "react-router-dom";

import axios from "axios";

export default function Btns({ status_name, hashId, image }) {
  let [submit, setSubmit] = useState("");
  let navigate = useNavigate();

  let btns;


  const apiChangeHandler = (ipayed) => {
    setSubmit(ipayed ? "payed" : "cancel");
    axios
      .put("/order/" + hashId, {
        ipayed,
      })
      .catch(() => {})
      .finally(() => {
        setSubmit("");
      });
  };

  const apiReverifyHandler = () => {
    setSubmit("reverify");
    axios
      .put("/order/" + hashId + "/reverify", {
        image,
      })
      .catch(() => {})
      .finally(() => {
        setSubmit("");
      });
  };

  if (status_name == "client_payment") {
    btns = (
      <>
        <Grid item xs={12} md={6}>
          <Button
            disabled={submit == "payed"}
            onClick={() => apiChangeHandler(true)}
            fullWidth
            variant="contained"
          >
            Я оплатил
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            disabled={submit == "cancel"}
            onClick={() => apiChangeHandler(false)}
            fullWidth
            variant="outlined"
          >
            Отменить обмен
          </Button>
        </Grid>
      </>
    );
  } else if (status_name == "rejected" || status_name == "successfully") {
    btns = (
      <>
        <Grid textAlign="center" item xs={12} md={6}>
          <Button onClick={() => navigate("/")} fullWidth variant="contained">
            Новый обмен
          </Button>
        </Grid>
      </>
    );
  } else if (status_name == "reverify_card" && image) {
    btns = (
      <>
        <Grid textAlign="center" item xs={12} md={6}>
          <Button
            disabled={submit == "reverify"}
            onClick={apiReverifyHandler}
            fullWidth
            variant="contained"
          >
            Отправить
          </Button>
        </Grid>
      </>
    );
  }

  if (!btns) return null;

  return (
    <Grid justifyContent="center" sx={{ mt: 0 }} container spacing={2}>
      {btns}
    </Grid>
  );
}
