import { Drawer, Box, Typography } from "@mui/material";

import HeaderMenu from "components/HeaderMenu";

import { NavLink } from "react-router-dom";

import { Icon } from "@iconify/react";

import AccStore from "store/account";

import { useNavigate } from "react-router-dom";

const links = [
  {
    name: "Профиль",
    link: "/account/profile",
    id: 1,
    icon: "mingcute:profile-line",
  },
  {
    name: "Заказы",
    link: "/account/orders",
    id: 2,
    icon: "gg:arrows-exchange-alt",
    forVerified: true,
  },
  {
    name: "Реферальная программа",
    link: "/account/referrals",
    id: 3,
    icon: "ph:users-bold",
    forVerified: true,
  },
];

function ClientMenu({ show, handleClose }) {
  let navigate = useNavigate();

  return (
    <Drawer
      anchor={"bottom"}
      open={show}
      sx={{
        "& .MuiDrawer-paper": {
          minHeight: "100vh",
          maxHeight: "100vh",
          bgcolor: (t) => t.palette.background.default,
          pt: 3,
          px: 3,
          pb: 3,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      }}
    >
      <Box>
        <HeaderMenu closeHandler={handleClose} style={{ mb: 5 }} />
        <Box>
          {links.map((l) => {
            return (
              <Typography
                onClick={handleClose}
                sx={{
                  fontSize: "20px",
                  textDecoration: "none",
                  fontWeight: 200,
                  display: "flex",
                  mb: 2,
                  color: "#DAD4EA",
                  "&.active": {
                    color: "#fff",
                    fontWeight: 600
                  },
                  alignItems: "center",
                }}
                to={l.link}
                component={NavLink}
                variant="body1"
              >
                <Icon fontSize="24px" icon={l.icon} />
                <p style={{ margin: "0px 0px 0px 10px" }}>{l.name}</p>
              </Typography>
            );
          })}
          <Typography
            onClick={() => {
              AccStore.logoutAccount();
              navigate("/");
              handleClose();
            }}
            sx={{
              mt: 8,
              display: "flex",
              alignItems: "center",
              transition: "0.2s all",
              color: (t) => t.palette.primary.link,
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            <Icon fontSize="24px" icon="mdi:logout" />
            <span style={{ marginLeft: "8px" }}>Выход</span>
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
}

export default ClientMenu;
