// Mui
import { Box, Typography } from "@mui/material";

// Router
import { Link } from "react-router-dom";

// Assets
import logoPng from "assets/images/logo.png";

function Logo({ name = "Hermex", handler = () => {} }) {
  return (
    <Box
      onClick={handler}
      sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    >
      <img title="Hermex" alt="Hermex" width="52" height="52" src={logoPng} />
      <Typography
        component={Link}
        to="/"
        sx={{ ml: 1.5, textDecoration: "none" }}
        variant="body1"
      >
        {name}
      </Typography>
    </Box>
  );
}

export default Logo;
