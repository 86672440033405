import config from "./configs/api";
import io from "socket.io-client";

let socket;

function connect() {
  socket = io(config.SERVER, {
    transports: ["polling", "flashsocket", "websocket"],
    reconnection: true,
  });

  socket.on("connect", function () {
    // console.debug("connect fired!");
  });
 
  socket.on("disconnect", function () {
    // console.debug("disconnect fired!");
  });
  socket.on("reconnect", function () {
    // console.debug("reconnect fired!");
  });
}

function authenticate() {
//   const token = localStorage.getItem("token");
//   if (token) {
//     console.debug("authenticate");
//     socket.emit("authenticate", token);
//   }
}

// console.log(socket, 'socket')

export { authenticate, connect, socket };

export default socket;
