import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  FormHelperText,
} from "@mui/material";

import { observer } from "mobx-react-lite";

import { Controller } from "react-hook-form";

import StoreAccount from "store/account";

import AgreementConfirm from "modules/AgreementConfirm/AgreementConfirm";

function Unauth({
  style = {},
  isAgreement,
  setIsAgreement,
  onSubmit,
  control,
  errors,
  register,
  isSubmit
}) {
  let isAuth = StoreAccount.isAuth;

  const submitBtn = (
    <Button
      disabled={isSubmit}
      onClick={onSubmit}
      sx={{ height: "56px", fontSize: "18px" }}
      size="large"
      fullWidth
      variant="contained"
    >
      Перейти к оплате
    </Button>
  );

  if (isAuth)
    return (
      <Grid sx={{ ...style }} justifyContent="center" container spacing={4}>
        <Grid item xs={12} md={6}>
          {submitBtn}
        </Grid>
      </Grid>
    );

  return (
    <Card
      sx={{
        border: "2px solid #3A3255",
        borderRadius: 4,
        bgcolor: "#211A35",
        ...style,
      }}
    >
      <CardContent
        sx={{
          px: 4,
          py: 2,
        }}
      >
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={{ mb: 2 }} variant="h6">
                Ваш e-mail
              </Typography>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      fullWidth
                      placeholder="example@mail.com"
                      value={value}
                      onChange={onChange}
                      {...register("email", {
                        required: "Пожалуйста, заполните это поле!",
                        maxLength: 100,
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Неверный формат  почты",
                        },
                      })}
                      error={Boolean(errors?.email?.message)}
                    />
                  );
                }}
              />
              {errors?.email?.message && (
                <FormHelperText
                  sx={{ fontSize: "14px", fontWeight: "500" }}
                  error
                >
                  {errors?.email?.message}
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid alignSelf="end" item xs={12} md={6}>
            {submitBtn}
          </Grid>
        </Grid>

        <Controller
          name="agreement"
          control={control}
          render={({ field: { onChange, value } }) => {
            return <AgreementConfirm
              {...register("agreement", {
                validate: (value) => {
                  if (!value) {
                    return "Please, choose a value";
                  }
                },
              })}
              error={Boolean(errors?.agreement?.message)}
              style={{ mt: 3 }}
              checked={value}
              setChecked={onChange}
            />;
          }}
        />
      </CardContent>
    </Card>
  );
}

export default observer(Unauth);
