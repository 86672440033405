import { useState } from "react";

import {
  Box,
  CardContent,
  Typography,

} from "@mui/material";

import moment from "moment";
import "moment/locale/ru";


import Copy from "components/Copy";

function Desc({ style = {}, order_id, created_at }) {
  return (
    <Box
      sx={{
        ...style,
      }}
    >
      <CardContent sx={{ p: "12px 18px !important" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography fontWeight="600" variant="h5">
            Заявка № {order_id}
          </Typography>
          <Copy text={order_id} />
        </Box>

        <Typography color="#9D94B5" sx={{ mt: 1 }} variant="body2">
          {moment(created_at).format("HH:mm DD.MM.YYYY")}
        </Typography>
      </CardContent>
    </Box>
  );
}

export default Desc;
