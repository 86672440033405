import { Box, Card, CardContent, Typography } from "@mui/material";

import { Icon } from "@iconify/react";

function BreakLayout() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          border: "2px solid #3A3255",
          borderRadius: 4,
          bgcolor: "#211A35",
          mt: 5,
          width: "max-content",
        }}
      >
        <CardContent
          sx={{
            px: 3,
            py: 3,
            "& svg": {
              color: "#fff",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Icon icon="ant-design:rest-outlined" fontSize="82px" />
          <Typography sx={{ mt: 2 }} textAlign="center" variant="h5">
            Сервис на перерыве.
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body2" textAlign="center">
            Просим прощения за неудобство. В скором времени мы вернемся к
            работе!
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default BreakLayout;
