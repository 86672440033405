import { useState } from "react";

import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Collapse,
} from "@mui/material";

// Icon
import { Icon } from "@iconify/react";
import Color from "color";

function FAQCard({ title, text, style = {} }) {
  let [show, setShow] = useState(false);

  return (
    <Card
      onClick={() => setShow(!show)}
      sx={{ borderRadius: 2, cursor: "pointer", ...style }}
    >
      <CardContent sx={{ p: "16px 24px !important" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography noWrap variant="h6">
            {title}
          </Typography>
          <IconButton
            sx={{
              ml: 3,
              bgcolor: "#85BAEA",
              "&:hover": {
                bgcolor: `${Color("#85BAEA").alpha(0.7)} !important`,
              },
              width: 36,
              height: 36,
            }}
          >
            <Icon icon={show ? "icon-park:up" : "icon-park:down"} />
          </IconButton>
        </Box>
        <Collapse in={show}>
          <Typography
            variant="body1"
            sx={{
              py: 2,
              px: 2,
              fontWeight: 200,
              fontSize: "16px",
              lineHeight: "24px",
            }}
            dangerouslySetInnerHTML={{ __html: text }}
          ></Typography>
        </Collapse>
      </CardContent>
    </Card>
  );
}

export default FAQCard;
