// Mui
import { Grid, Box, Typography } from "@mui/material";

function RecentExchanges() {
  return (
    <Box>
      <Typography variant="h3">Последние обмены</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
    </Box>
  );
}

export default RecentExchanges;
