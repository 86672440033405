import { Box, Typography, Card, CardContent } from "@mui/material";

// Routing
import { Link } from "react-router-dom";

function RegisterConfirm() {
  return (
    <Box
      sx={{
        display: "flex",
        mt: 5,
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h4"> Подтвердите e-mail</Typography>
      <Card
        sx={{
          border: "2px solid #3A3255",
          borderRadius: 4,
          bgcolor: "#211A35",
          height: "100%",
          mt: 4,
        }}
      >
        <CardContent
          sx={{
            p: "32px 24px !important",
            display: "flex",
            flexDirection: "column",
          }}
          noValidate
        >
          <Typography textAlign='center' variant="body1">
            Мы отправили вам письмо на почту.<br/>
            Для подтверждения почты перейдите
            по ссылке из письма.
          </Typography>
          {/* <Box
        sx={{
          mt: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography>Не получили письмо?</Typography>
        <Typography
          variant="body2"
          sx={{ ml: 1, textDecoration: "none" }}
          component={Link}
          to="/register"
          color="primary.link"
        >
          Отправить снова
        </Typography>
      </Box> */}
          <Typography
            variant="body2"
            textAlign='center'
            sx={{ mt: 2, textDecoration: "none" }}
            component={Link}
            to="/login"
            color="primary.link"
          >
            Войти
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default RegisterConfirm;
