import { useEffect, useState } from "react";
import { Box, Container, Grid, Card, Button } from "@mui/material";

import Rate from "./Rate";
import Desc from "./Desc";
import Stepper from "./Stepper";
import Reqs from "./Reqs";
import StatusInfoCard from "./StatusInfoCard";
import Btns from "./Btns";

import { useNavigate, useSearchParams } from "react-router-dom";

import axios from "axios";

import { socket } from "socket";


function Order() {
  const [image, setImage] = useState('');

  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  let hash = searchParams.get("hid");

  // States
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!hash) {
      navigate("/");
    }
    axios.get(`/order/${hash}`).then((r) => {
      setData(r.data);
    });
    socket.emit(`order_subscribe`, hash);
    socket.on(`upd_order_${hash}`, (o) => {
      setData(o);
    });

    return () => {
      socket.off(`upd_order_${hash}`);
    };
  }, []);

  if (!data) return null;

  return (
    <Container sx={{ my: 6, pb: "1px" }} maxWidth="lg">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item xs={12} md={5}>
          <Desc
            created_at={data.created_at}
            status_name={data.status_name}
            order_id={data.order_id}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Rate
            expire_time={data.expire_time}
            sell={data.rate_sell}
            buy={data.rate_buy}
            status_name={data.status_name}
          />
        </Grid>
      </Grid>
      <Stepper status_name={data.status_name} style={{ mt: 4 }} />
      <Reqs
        sell_info={data.sell_info}
        buy_info={data.buy_info}
        style={{ mt: 4 }}
      />
      <Grid sx={{ mt: 4 }} justifyContent="center" container spacing={2}>
        <Grid item xs={12} md={7}>
          <StatusInfoCard
            payment_sum={data.sell_info.sum}
            payment_requisites={data.payment_requisites}
            error_comment={data.error_comment}
            status_name={data.status_name}
            buy_code={data.sell_info.code}
            image={image}
            setImage={setImage}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: 1 }} justifyContent="center" container spacing={2}>
        <Grid item xs={12} md={7}>
          <Btns image={image} hashId={data.hash} status_name={data.status_name} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Order;
