import { useState, useEffect } from "react";

import { Box, Typography, Grid } from "@mui/material";

import Part from "./Part";
import Unauth from "./Unauth";
import Rate from "./Rate";
import BreakLayot from "./BreakLayot";
import VerifyDrawer from "./VerifyDrawer";

import { useForm, Controller } from "react-hook-form";
import axios from "axios";

import { Icon } from "@iconify/react";

import { socket } from "../../../socket";

import { observer } from "mobx-react-lite";
import AccountStore from "store/account";
import { useNavigate } from "react-router-dom";

function uniqueBy(arr, prop) {
  let list = [...arr];

  let result = [];

  list.forEach((it) => {
    if (!result.find((r) => r[prop] == it[prop])) {
      result.push(it);
    }
  });

  return result;
}

function accuracyCalc(accuracy, sum) {
  return Math.round(sum * Math.pow(10, accuracy)) / Math.pow(10, accuracy);
}
function getReferrer() {
  return document.referrer.indexOf("localhost") === -1 ? document.referrer : "";
}

function Calculate() {
  // let [sellCurrencies, setSellCurrencies] = useState([]);
  // let [buyCurrencies, setBuyCurrencies] = useState([]);
  let [operations, setOperations] = useState([]);
  let [isBreak, setIsBreak] = useState(false);
  let [image, setImage] = useState("");
  let [isAgreement, setIsAgreement] = useState(false);
  let [takeAmount, setTakeAmount] = useState("");
  let [giveAmount, setGiveAmount] = useState("");
  let [dir, setDir] = useState("");
  let [showImage, setShowImage] = useState(false);
  let [isSubmit, setIsSubmit] = useState(false);

  let [errorAmount, setErrorAmount] = useState({
    sell: "",
    buy: "",
  });

  let [tid, setTid] = useState("");
  let [gid, setGid] = useState("");

  let isAuth = AccountStore.isAuth;
  let rid = AccountStore.rid;

  let navigate = useNavigate();

  // Hooks
  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });

  useEffect(() => {
    if (!gid) return;
    reset();
  }, [gid]);

  useEffect(() => {
    axios.get(`/operation`).then(({ data }) => {
      if (data.status == "break") {
        setIsBreak(true);
      }
      setOperations(data.list);
      setTid(data.list[0].sell_info.id);
      setGid(data.list[0].buy_info.id);
    });
    socket.on(`rate_operation_update`, updateOper);
    socket.on(`event_set_break`, (bool) => {
      setIsBreak(bool);
    });

    return () => {
      socket.off("rate_operation_update");
      socket.off("event_set_break");
    };
  }, []);

  const updateOper = (e) => {
    setOperations((list) => {
      return list.map((o) => {
        return o.id == e.id
          ? Object.assign({}, o, { rate_info: e.rate_info })
          : o;
      });
    });
  };

  // Calculate

  let op = operations.find(
    (o) => o.sell_info.id == tid && o.buy_info.id == gid
  );
  let opId = op?.id;

  let tfields = op?.sell_info?.fill_fields || [];
  let gfields = op?.buy_info?.fill_fields || [];
  let tdata = op?.sell_info;
  let gdata = op?.buy_info;
  let sellCurrencies =
    uniqueBy(
      operations.map((o) => o.sell_info),
      "id"
    ) || [];
  let buyCurrencies =
    operations.filter((o) => o.sell_info.id == tid).map((o) => o.buy_info) ||
    [];
  buyCurrencies = uniqueBy(buyCurrencies, "id");

  // Handlers
  const onSubmit = handleSubmit((d) => {
    // validate sums
    let isValid = amountValidator();
    if (!isValid) return;

    let client_info = {
      referrer: getReferrer(),
      email: isAuth ? undefined : d.email,
      rid: rid,
    };
    let order_info = {
      sell_amount: +takeAmount,
      buy_amount: +giveAmount,
      eid: op.id,
    };

    let fields_info = {
      sell_list: tfields.map((f) => Object.assign({}, f, { value: d[f.id] })),
      buy_list: gfields.map((f) => Object.assign({}, f, { value: d[f.id] })),
    };

    let fetchData = {
      client_info,
      order_info,
      fields_info,
      image_name: image || "",
    };

    setIsSubmit(true);
    axios
      .post("/order/create", fetchData)
      .then(({ data }) => {
        if (data.status_code == "verify_card") {
          setShowImage(true);
        } else {
          navigate("/order/?hid=" + data.hash);
          setImage("");
        }
      })
      .catch((e) => {
        let errors = e?.response?.data?.errors || [];
        errors.map((er) => {
          setError(er.field_id, {
            message: er.error_message,
          });
        });
        // console.log(errors, "errors");
      })
      .finally(()=>{
        setIsSubmit(false);
      
      })

    // console.log(fetchData, "fetchData");
  });

  let rate_data = op?.rate_info;
  let sell_accuracy = op?.sell_info?.accuracy || 0;
  let buy_accuracy = op?.buy_info?.accuracy || 0;

  // amount validate

  useEffect(() => {
    let sell = rate_data?.sell;
    let buy = rate_data?.buy;
    if (!opId) return;
    if (!dir) return;
    if (dir == "sell") {
      setGiveAmount(accuracyCalc(buy_accuracy, +takeAmount * (buy / sell)));
    } else if (dir == "buy") {
      setTakeAmount(accuracyCalc(sell_accuracy, +giveAmount * (sell / buy)));
    }
  }, [opId]);

  useEffect(() => {
    amountValidator(true);
  }, [takeAmount, giveAmount]);

  function amountValidator(init) {
    if (init) {
      if (!takeAmount) return;
    }

    let updErrorAmount = {
      sell: "",
      buy: "",
    };

    let isValid = true;

    if (takeAmount < tdata?.min_sum) {
      updErrorAmount.sell = `Минимум ${tdata?.min_sum} ${tdata?.code}`;
      isValid = false;
    }
    if (takeAmount > tdata?.max_sum) {
      updErrorAmount.sell = `Максимум ${tdata?.max_sum} ${tdata?.code}`;
      isValid = false;
    }

    if (giveAmount > gdata?.reserve_value) {
      updErrorAmount.buy = `Максимум ${gdata?.reserve_value} ${gdata?.code}`;
      isValid = false;
    }

    setErrorAmount(updErrorAmount);

    return isValid;
  }

  const changeTakeAmount = (v) => {
    let sell = rate_data.sell;
    let buy = rate_data.buy;

    setTakeAmount(v);
    setGiveAmount(accuracyCalc(buy_accuracy, +v * (buy / sell)));
    setDir("sell");
  };

  const changeGiverAmount = (v) => {
    let sell = rate_data.sell;
    let buy = rate_data.buy;

    setGiveAmount(v);
    setTakeAmount(accuracyCalc(sell_accuracy, +v * (sell / buy)));
    setDir("buy");
  };

  const revBtn = (
    <Box
      onClick={reversePair}
      sx={{
        bgcolor: op?.reverseId ? "#5EC4D1" : "#9D94B5",
        color: "white",
        width: "58px",
        height: "47px",
        borderRadius: "23px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "all 0.3s",

        "&:hover": {
          bgcolor: op?.reverseId ? "#5ec4d1c9" : undefined,
        },
      }}
    >
      <Icon fontSize="24px" icon="jam:refresh-reverse" />
    </Box>
  );

  function changeSetId(sid) {
    let op = operations.find((o) => o.sell_info.id == sid);
    setTid(sid);
    setGid(op.buy_info.id);
  }

  function reversePair() {
    if (!op.reverseId) return;
    setTid(gid);
    setGid(tid);
  }

  let hd = tdata && gdata;

  if (isBreak) {
    return <BreakLayot />;
  }

  if (!hd) return null;

  return (
    <Box sx={{ mt: 4 }}>
      <VerifyDrawer
        show={showImage}
        closeHandler={() => {
          setShowImage(false);
          setImage("");
        }}
        submitHandler={onSubmit}
        setImage={setImage}
        image={image}
      />
      <Grid
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 4 }}
        container
        spacing={2}
      >
        <Grid item xs={12} md={8}>
          <Typography variant="h4">
            Обмен {tdata?.name} ({tdata?.code}) на {gdata?.name} ({gdata?.code})
          </Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "flex-end" }}
          item
          xs={12}
          md={4}
        >
          <Rate
            rate_info={op.rate_info}
            sell_code={tdata?.code}
            buy_code={gdata?.code}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={5.5}>
          <Part
            setId={changeSetId}
            data={tdata}
            title="Отдаете"
            dir="buy"
            fields={tfields}
            currencies={sellCurrencies}
            amount={takeAmount}
            setAmount={changeTakeAmount}
            errorAmount={errorAmount.sell}
            errors={errors}
            register={register}
            getValues={getValues}
            setValue={setValue}
            control={control}
          />
        </Grid>
        <Grid alignSelf="center" item xs={12} md={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              my: 2,
            }}
          >
            {revBtn}
          </Box>
        </Grid>
        <Grid item xs={12} md={5.5}>
          <Part
            errorAmount={errorAmount.buy}
            setId={setGid}
            data={gdata}
            dir="sell"
            title="Получаете"
            currencies={buyCurrencies}
            fields={gfields}
            amount={giveAmount}
            setAmount={changeGiverAmount}
            errors={errors}
            register={register}
            getValues={getValues}
            setValue={setValue}
            control={control}
          />
        </Grid>
      </Grid>
      <Grid justifyContent="center" container spacing={2}>
        <Grid item xs={12} md={8}>
          <Unauth
            onSubmit={onSubmit}
            isAgreement={isAgreement}
            setIsAgreement={setIsAgreement}
            style={{ mt: 4, mb: 4 }}
            control={control}
            errors={errors}
            register={register}
            isSubmit={isSubmit}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default observer(Calculate);
