// Mui
import { Box, Typography, Container } from "@mui/material";

//modules
import Features from "./Features";
import OfferAskQuestion from "./OfferAskQuestion";
import FAQ from "./FAQ";
import RecentExchanges from "./RecentExchanges";
import Calculate from "./Calculate/Calculate";

function Main() {
  return (
    <Container maxWidth="lg">
      <Calculate />
      {/* <Features />
      <RecentExchanges />
      <FAQ /> */}
      {/* <OfferAskQuestion /> */}
    </Container>
  );
}

export default Main;
