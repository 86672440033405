import { useState, useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";

// Fetch
import axios from "axios";

// Components
import FAQCard from "components/FAQ/Card/Card";

function FAQ() {
  const [list, setList] = useState(null);

  useEffect(() => {
    axios.get("/content/faq-list").then((r) => {
      setList(r.data);
    });
  }, []);

  if (!list) return null;

  return (
    <Box sx={{ my: 4 }}>
      <Container maxWidth="lg">
        <Typography
          sx={{  mb: "50px" }}
          textAlign="center"
          variant="h4"
        >
          Вопросы и ответы
        </Typography>
      </Container>
      <Container maxWidth="md">
        {list.map((f) => (
          <FAQCard title={f.article_title} text={f.article_text} style={{ mb: "10px" }} />
        ))}
      </Container>
    </Box>
  );
}

export default FAQ;
