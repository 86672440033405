import { Box, Typography, CardContent } from "@mui/material";

function Rate({ rate_info, sell_code, buy_code }) {
  return (
    <Box
      sx={{
        border: "1.5px solid #5EC4D1",
        borderRadius: 4,
        bgcolor: "#211A35",
        width: 'max-content',
      }}
    >
      <CardContent
        sx={{
          p: "12px 18px !important",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{color: '#D9D3E8', mr: 1}}>Курс:</Typography>
        <Typography>
          {rate_info.sell} {sell_code} = {rate_info.buy} {buy_code}
        </Typography>
      </CardContent>
    </Box>
  );
}

export default Rate;
