import { Routes, Route, Navigate } from "react-router-dom";
import { Box } from "@mui/material";

// Pages
import Main from "pages/Main/Main";
import FAQ from "pages/FAQ";
import Contacts from "pages/Contacts";
import Login from "pages/Auth/Login";
import Register from "pages/Auth/Register";
import RegisterConfirm from "pages/Auth/RegisterConfirm";
import ResetPassword from "pages/Auth/ResetPassword";
import RecoveryPassword from "pages/Auth/RecoveryPassword";
import Rules from "pages/Rules";
import Cooperation from "pages/Cooperation";

// Modules
import AccountMenu from "modules/AccountMenu/AccountMenu";

// Accaount
import Profile from "pages/Profile/Profile";
import Orders from "pages/Orders/Orders";
import Referrals from "pages/Referrals/Referrals";

// Order
import Order from "pages/Order/Order";
import { Withdraw } from "pages/Withdraw";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/our-contacts" element={<Contacts />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register/confirm" element={<RegisterConfirm />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/recovery-password" element={<RecoveryPassword />} />
      <Route path="/rules" element={<Rules />} />
      <Route path="/сooperation" element={<Cooperation />} />
      <Route path="/account" element={<AccountMenu />}>
        <Route path="/account/profile" element={<Profile />} />
        <Route path="/account/orders" element={<Orders />} />
        <Route path="/account/referrals" element={<Referrals />} />
        <Route path="/account/referrals/withdraw" element={<Withdraw />} />
      </Route>
      <Route path="/order" element={<Order />} />

      {/* <Route path="/dashboard" element={<Dashboard />} /> */}
    </Routes>
  );
}

export default Router;
