import { useState, useEffect } from "react";
// Mui
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";

import SumsCard from "./SumsCard";
import ReferralsHistory from "./ReferralsHistory";

import axios from "axios";

import Copy from "components/Copy";

import Color from "color";

import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

function Referrals() {
  let [data, setData] = useState(null);

  let navigate = useNavigate();

  // /account/referral

  useEffect(() => {
    axios.get("/account/referral").then((res) => setData(res.data));
  }, []);

  if (!data) return null;

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              border: "2px solid #3A3255",
              borderRadius: 4,
              bgcolor: "#211A35",
              height: "100%",
            }}
          >
            <CardContent>
              <Typography sx={{ mb: 2, fontSize: "20px" }} fontWeight="600">
                Реферальная программа
              </Typography>
              <Typography sx={{ color: "#E9E1FF" }} variant="body2">
                Приводите друзей и знакомых по Вашей реферальной ссылке,
                и получайте 30% от прибыли нашего сервиса, за каждый успешный обмен. 
              </Typography>

              <Typography sx={{ color: "#E9E1FF", mt: 3 }}>
                Реферальная ссылка
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Box
                  sx={{
                    mr: 1,
                    p: "6px 12px",
                    flexGrow: 1,
                    borderRadius: 3,
                    bgcolor: Color("#211A35").lighten(0.6).hex(),
                  }}
                >
                  <Typography>{data.rid}</Typography>
                </Box>
                <Copy text={data.rid} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <SumsCard
              balance={data.balance}
              income={data.income}
              withdraw={data.withdraw}
            />
            <Box sx={{ mt: 2 }}>
              <Button
                size="large"
                endIcon={<Icon fontSize="14px" icon="mingcute:right-fill" />}
                fullWidth
                variant="contained"
                onClick={()=>navigate('/account/referrals/withdraw')}
              >
                Заказать выплату
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ReferralsHistory list={data.history} />
    </Box>
  );
}

export default Referrals;
